import React from 'react';
import { Helmet } from 'react-helmet';

export const About = () => {
  return (
    <>
      <Helmet>
        <title>About ilikeimg - Professional Image Processing Tools</title>
        <meta name="description" content="Learn about ilikeimg's mission to provide accessible, professional-grade image processing tools powered by AI technology" />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">About ilikeimg</h1>
        <div className="prose prose-purple max-w-none">
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Our Mission</h2>
            <p className="text-gray-600">
              ilikeimg is dedicated to democratizing access to professional-grade image processing tools. 
              We combine cutting-edge AI technology with user-friendly interfaces to provide powerful 
              image manipulation capabilities to everyone, from professional designers to casual users.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Our Technology</h2>
            <p className="text-gray-600">
              We leverage advanced artificial intelligence and machine learning technologies to power 
              our image processing tools. Our platform includes state-of-the-art AI upscaling, 
              face merging, and text removal capabilities, alongside traditional image processing 
              tools for format conversion, compression, and editing.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Privacy & Security</h2>
            <p className="text-gray-600">
              Your privacy and data security are our top priorities. We process images client-side 
              whenever possible, implement temporary storage with automatic deletion, and maintain 
              strict data protection protocols. We never store your images permanently, and all 
              processing is done using secure, encrypted connections.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Our Commitment</h2>
            <p className="text-gray-600">
              We are committed to continuous improvement and innovation. We regularly update our 
              tools with the latest technological advancements while maintaining our commitment 
              to user privacy, security, and accessibility. Our freemium model ensures that 
              essential tools remain available to everyone while offering advanced features 
              to premium users.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};
export default About;