import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Minus } from 'lucide-react';

const FAQItem = ({ question, answer, isOpen, onClick }) => (
  <motion.div
    initial={false}
    className="border-b border-gray-200 last:border-0"
  >
    <button
      className="w-full py-6 flex justify-between items-center text-left"
      onClick={onClick}
    >
      <span className="text-lg font-medium text-gray-900">{question}</span>
      {isOpen ? (
        <Minus className="w-5 h-5 text-gray-500" />
      ) : (
        <Plus className="w-5 h-5 text-gray-500" />
      )}
    </button>
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="overflow-hidden"
        >
          <p className="pb-6 text-gray-600">{answer}</p>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
);

const TextRemoveFAQ = () => {
  const [openIndex, setOpenIndex] = React.useState(0);
  
  const faqs = [
    {
      question: "How does the text removal technology work?",
      answer: "Our AI-powered technology analyzes your image to identify text and watermarks, then intelligently reconstructs the background while maintaining image quality."
    },
    {
      question: "What types of text can be removed?",
      answer: "Our tool can remove various types of text including watermarks, subtitles, stamps, logos, and overlaid text. The effectiveness may vary depending on the complexity."
    },
    {
      question: "How many credits does text removal cost?",
      answer: "Each image processed costs 10 credits. Free users get 20 daily credits, while Premium users enjoy 100 daily credits."
    },
    {
      question: "What image formats are supported?",
      answer: "We support JPEG, PNG, and WebP formats. Maximum file size is 30MB per image."
    },
    {
      question: "Is my data kept private?",
      answer: "Yes, all uploaded images are processed securely and deleted immediately after processing. We never store your original or processed images."
    }
  ];

  return (
    <div className="max-w-3xl mx-auto px-4">
      {faqs.map((faq, index) => (
        <FAQItem
          key={index}
          question={faq.question}
          answer={faq.answer}
          isOpen={openIndex === index}
          onClick={() => setOpenIndex(openIndex === index ? -1 : index)}
        />
      ))}
    </div>
  );
};

export default TextRemoveFAQ;