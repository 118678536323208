import React, { useState, useContext, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import {
    AlertCircle,
    Plus,
    X,
    Download,
    Loader,
    Upload,
    ArrowRight
} from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
// import BeforeAfterTextRemove from './BeforeAfterTextRemove';
import TextRemoveFAQ from './TextRemoveFAQ';
import RemoveTextReviews from './RemoveTextReviews';
import JSZip from 'jszip';
import CreditAlert from './CreditAlert';
import { pricingService } from '../services/PricingService';
import { useCredits } from '../contexts/CreditContext';

// Constants
const MAX_DIMENSION = 1024;
const MAX_FILE_SIZE = 6 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/webp'];
const CREDITS_PER_IMAGE = 10;

const ProcessingAnimation = () => (
    <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg 
                  flex items-center justify-center backdrop-blur-sm">
        <div className="space-y-4 text-center">
            <div className="inline-flex items-center px-4 py-2 bg-white rounded-full">
                <Loader className="w-4 h-4 text-purple-600 animate-spin mr-2" />
                <span className="text-sm text-purple-600 font-medium">Processing</span>
            </div>
            <motion.div
                initial={{ width: "0%" }}
                animate={{ width: "100%" }}
                transition={{ duration: 2, repeat: Infinity }}
                className="h-1 bg-purple-500 rounded-full"
            />
        </div>
    </div>
);

const ImagePreview = ({ file, onRemove, isProcessed = false }) => {
    return (
        <motion.div
            layout
            className="relative group"
        >
            <div className="relative w-full pt-[100%] max-w-[160px] rounded-lg overflow-hidden bg-gray-100">
                <img
                    src={isProcessed ? URL.createObjectURL(file.processedBlob) : file.preview}
                    alt=""
                    className="absolute inset-0 w-full h-full object-cover transition-transform 
                       duration-300 group-hover:scale-105"
                />

                {!isProcessed && !file.completed && (
                    <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 
                           transition-opacity duration-300">
                        <button
                            onClick={() => onRemove(file.id)}
                            className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-lg 
                                     opacity-0 group-hover:opacity-100 transition-opacity hover:bg-gray-100"
                        >
                            <X className="w-3 h-3 text-gray-600" />
                        </button>
                    </div>
                )}

                {file.progress > 0 && file.progress < 100 && <ProcessingAnimation />}

                {isProcessed && file.completed && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="absolute bottom-1 right-1"
                    >
                        <button
                            onClick={() => file.onDownload()}
                            className="p-1.5 bg-white rounded-lg shadow-lg hover:bg-gray-50 
                                     transition-colors duration-200"
                        >
                            <Download className="w-3 h-3 text-gray-600" />
                        </button>
                    </motion.div>
                )}
            </div>

            <div className="mt-1 px-1">
                <p className="text-xs text-gray-600 truncate" title={file.name}>
                    {file.name}
                </p>
                <p className="text-[10px] text-gray-400">
                    {Math.round(file.size / 1024)}KB
                </p>
            </div>
        </motion.div>
    );
};

const AddMoreButton = ({ onClick, disabled }) => {
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        e.preventDefault();
        if (e.target.files?.length) {
            const files = Array.from(e.target.files);
            onClick(files);
            e.target.value = '';
        }
    };

    return (
        <motion.div
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
        >
            <div
                onClick={() => !disabled && fileInputRef.current?.click()}
                className={`w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg
                   hover:border-purple-500 transition-colors flex items-center justify-center
                   ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
            >
                <Plus className="w-8 h-8 text-gray-400" />
                <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    multiple
                    accept="image/png,image/jpeg,image/webp"
                    disabled={disabled}
                />
            </div>
        </motion.div>
    );
};

const UploadZone = ({ onUpload, processing }) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const files = Array.from(e.dataTransfer.files);
        onUpload(files);
    };

    const handleFileChange = (e) => {
        if (e.target.files?.length) {
            onUpload(Array.from(e.target.files));
            e.target.value = '';
        }
    };

    return (
        <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", duration: 0.6 }}
            onDragOver={handleDrag}
            onDragEnter={handleDragIn}
            onDragLeave={handleDragOut}
            onDrop={handleDrop}
            className={`relative w-full max-w-2xl mx-auto p-8 border-2 border-dashed rounded-xl
                       transition-colors duration-200 ease-in-out cursor-pointer
                       ${isDragging ? 'border-purple-500 bg-purple-50' : 'border-gray-300 hover:border-purple-500'}
                       ${processing ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
            <motion.div
                whileHover={{ scale: isDragging ? 1 : 1.02 }}
                className="text-center"
            >
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <p className="mt-4 text-sm font-medium text-gray-900">
                    Drop your images here
                </p>
                <p className="mt-1 text-xs text-gray-500">
                    PNG, JPG, WebP up to 6MB
                </p>
                <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => fileInputRef.current?.click()}
                    className="mt-4 px-4 py-2 bg-purple-600 text-white rounded-lg 
                             hover:bg-purple-700 transition-colors"
                    disabled={processing}
                >
                    Select Files
                </motion.button>
                <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    multiple
                    accept="image/png,image/jpeg,image/webp"
                    disabled={processing}
                />
            </motion.div>
        </motion.div>
    );
};

const SplitView = ({ files, onRemove, onProcess, processing, onAddMore }) => {
    const hasProcessedFiles = files.some(f => f.completed);
    const unprocessedCount = files.filter(f => !f.completed).length;

    const downloadAllAsZip = async () => {
        const zip = new JSZip();
        files.forEach(file => {
            if (file.completed && file.processedBlob) {
                zip.file(`text_removed_${file.name}`, file.processedBlob);
            }
        });
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipBlob);
        link.download = 'processed_images.zip';
        link.click();
        URL.revokeObjectURL(link.href);
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white rounded-xl shadow-lg p-4 max-w-4xl mx-auto">
            <div className="space-y-3">
                <div className="flex items-center justify-between pb-2 border-b">
                    <h3 className="text-base font-semibold text-gray-900">
                        Uploaded Images
                    </h3>
                    <span className="text-xs text-gray-500">
                        {files.length} {files.length === 1 ? 'file' : 'files'}
                    </span>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 max-h-[400px] overflow-y-auto p-2">
                    <AnimatePresence>
                        {files.map(file => (
                            <ImagePreview
                                key={file.id}
                                file={file}
                                onRemove={onRemove}
                            />
                        ))}
                        <AddMoreButton
                            onClick={onAddMore}
                            disabled={processing}
                        />
                    </AnimatePresence>
                </div>
            </div>

            <div className="space-y-3">
                <div className="flex items-center justify-between pb-2 border-b">
                    <h3 className="text-base font-semibold text-gray-900">
                        Processed Images
                    </h3>
                    {hasProcessedFiles && (
                        <button
                            onClick={downloadAllAsZip}
                            className="inline-flex items-center px-2 py-1 text-xs bg-gray-100 
                                     text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                        >
                            <Download className="w-3 h-3 mr-1" />
                            Download All
                        </button>
                    )}
                </div>

                <div className="p-2">
                    {hasProcessedFiles ? (
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 max-h-[400px] overflow-y-auto">
                            <AnimatePresence>
                                {files.map(file => (
                                    file.completed && (
                                        <ImagePreview
                                            key={`processed-${file.id}`}
                                            file={file}
                                            isProcessed={true}
                                        />
                                    )
                                ))}
                            </AnimatePresence>
                        </div>
                    ) : (
                        <div className="min-h-[200px] flex items-center justify-center">
                            <p className="text-sm text-gray-400 text-center">
                                {processing ? 
                                    `Processing ${unprocessedCount} ${unprocessedCount === 1 ? 'image' : 'images'}...` :
                                    'Processed images will appear here'}
                            </p>
                        </div>
                    )}
                </div>
            </div>

            {!processing && files.length > 0 && !hasProcessedFiles && (
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onProcess}
                    className="fixed bottom-8 right-8 z-50 inline-flex items-center px-6 py-3 
                             bg-purple-600 text-white rounded-full shadow-lg hover:bg-purple-700 
                             transition-colors duration-200 font-medium"
                >
                    <span>Process Images</span>
                    <ArrowRight className="w-5 h-5 ml-2" />
                </motion.button>
            )}
        </div>
    );
};

const TextRemovalCredit = ({ isOpen, files, credits, isPremium, onClose, operationCost }) => {
    if (!isOpen) return null;

    const unprocessedFiles = files.filter(f => !f.completed && !f.error).length;
    const requiredCredits = unprocessedFiles * operationCost;

    return (
        <div 
            className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4"
            onClick={onClose}
        >
            <div 
                className="w-full max-w-md px-4" 
                onClick={e => e.stopPropagation()}
            >
                <CreditAlert
                    currentCredits={credits}
                    required={requiredCredits}
                    isPremium={isPremium}
                />
            </div>
        </div>
    );
};

// Main TextRemoval component - continues in next section...
const TextRemoval = () => {
    const { user } = useContext(AuthContext);
    const { credits, refreshCredits } = useCredits();
    const [files, setFiles] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [view, setView] = useState('upload');
    const [showCreditDialog, setShowCreditDialog] = useState(false);
    const [operationCost, setOperationCost] = useState(CREDITS_PER_IMAGE);

    useEffect(() => {
        const fetchPricing = async () => {
            try {
                const pricing = await pricingService.getPricing();
                setOperationCost(pricing?.credits?.costs?.TEXT_REMOVE ?? CREDITS_PER_IMAGE);
            } catch (error) {
                console.error('Failed to fetch pricing:', error);
                setOperationCost(CREDITS_PER_IMAGE);
            }
        };

        fetchPricing();
    }, []);

    useEffect(() => {
        return () => {
            files.forEach(file => {
                URL.revokeObjectURL(file.preview);
                if (file.processedBlob) {
                    URL.revokeObjectURL(URL.createObjectURL(file.processedBlob));
                }
            });
        };
    }, [files]);

    const resizeImage = async (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                if (img.width <= MAX_DIMENSION && img.height <= MAX_DIMENSION) {
                    resolve(file);
                    return;
                }

                const ratio = Math.min(MAX_DIMENSION / img.width, MAX_DIMENSION / img.height);
                const newWidth = Math.round(img.width * ratio);
                const newHeight = Math.round(img.height * ratio);

                const canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext('2d');
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                canvas.toBlob((blob) => {
                    resolve(new File([blob], file.name, {
                        type: 'image/jpeg',
                        lastModified: Date.now()
                    }));
                }, 'image/jpeg', 0.95);
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        });
    };

    const handleFiles = async (newFiles) => {
        if (!user) {
            setError(
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-gradient-to-r from-purple-50 to-blue-50 p-6 rounded-xl"
                >
                    <h3 className="text-lg font-semibold mb-2">Sign in to Continue</h3>
                    <p className="text-gray-600 mb-4">Create an account to get started</p>
                    <div className="flex gap-3">
                        <button
                            onClick={() => window.location.href = '/auth?isLogin=true'}
                            className="px-4 py-2 text-purple-600 border border-purple-200 rounded-lg hover:bg-purple-50"
                        >
                            Sign In
                        </button>
                        <button
                            onClick={() => window.location.href = '/auth?isLogin=false'}
                            className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                        >
                            Create Account
                        </button>
                    </div>
                </motion.div>
            );
            return;
        }

        try {
            const validFiles = await Promise.all(
                Array.from(newFiles)
                    .filter(file => {
                        const isValidType = SUPPORTED_FORMATS.includes(file.type);
                        const isValidSize = file.size <= MAX_FILE_SIZE;
                        return isValidType && isValidSize;
                    })
                    .map(async (file) => ({
                        id: Math.random().toString(36).substr(2, 9),
                        file: await resizeImage(file),
                        preview: URL.createObjectURL(file),
                        name: file.name,
                        size: file.size,
                        progress: 0,
                        completed: false,
                        error: null,
                        processedBlob: null
                    }))
            );

            if (validFiles.length) {
                setFiles(prev => [...prev, ...validFiles]);
                setError(null);
                setView('split');
            } else {
                setError('Please upload PNG, JPG or WebP files under 6MB');
            }
        } catch (err) {
            console.error('Error processing files:', err);
            setError('Error processing files. Please try again.');
        }
    };

    const removeFile = (id) => {
        setFiles(prev => {
            const file = prev.find(f => f.id === id);
            if (file) {
                URL.revokeObjectURL(file.preview);
                if (file.processedBlob) {
                    URL.revokeObjectURL(URL.createObjectURL(file.processedBlob));
                }
            }
            return prev.filter(f => f.id !== id);
        });
    };

    const processFiles = async () => {
        if (processing) return;

        const unprocessedFiles = files.filter(f => !f.completed && !f.error);
        const requiredCredits = unprocessedFiles.length * operationCost;

        if (credits < requiredCredits) {
            setShowCreditDialog(true);
            return;
        }

        setProcessing(true);
        setError(null);

        try {
            for (const file of unprocessedFiles) {
                try {
                    setFiles(prev => prev.map(f =>
                        f.id === file.id ? { ...f, progress: 10 } : f
                    ));

                    const formData = new FormData();
                    formData.append('image', file.file);

                    const response = await fetch(`${process.env.REACT_APP_API_URL}/remove-text`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        },
                        body: formData
                    });

                    if (!response.ok) {
                        if (response.status === 402) {
                            await refreshCredits();
                            throw new Error('Insufficient credits');
                        }
                        throw new Error('Processing failed');
                    }

                    const processedBlob = await response.blob();

                    setFiles(prev => prev.map(f =>
                        f.id === file.id ? {
                            ...f,
                            progress: 100,
                            completed: true,
                            processedBlob,
                            onDownload: () => {
                                const link = document.createElement('a');
                                link.href = URL.createObjectURL(processedBlob);
                                link.download = `text_removed_${f.name}`;
                                link.click();
                            }
                        } : f
                    ));

                    await refreshCredits();
                    await new Promise(resolve => setTimeout(resolve, 500));

                } catch (err) {
                    setFiles(prev => prev.map(f =>
                        f.id === file.id ? { ...f, progress: 0, error: err.message } : f
                    ));
                }
            }
        } finally {
            setProcessing(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50">
            <Helmet>
                <title>AI Text & Watermark Removal | Remove Text from Images Online</title>
                <meta name="description" content="Remove text and watermarks from images using advanced AI technology. Fast, accurate, and secure text removal tool." />
            </Helmet>

            <div className="max-w-7xl mx-auto px-4 py-12">
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-center mb-12"
                >
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        AI Text & Watermark Removal
                    </h1>
                    <p className="text-xl text-gray-600 mb-2">
                        Remove unwanted text and watermarks from your images with our advanced AI technology
                    </p>
                </motion.div>

                <AnimatePresence mode="wait">
                    {error && (
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            className="max-w-2xl mx-auto mb-6"
                        >
                            {typeof error === 'string' ? (
                                <div className="bg-red-50 p-4 rounded-lg flex items-start">
                                    <AlertCircle className="w-5 h-5 text-red-400 mt-0.5" />
                                    <p className="ml-3 text-sm text-red-700">{error}</p>
                                </div>
                            ) : error}
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence mode="wait">
                    {view === 'upload' ? (
                        <UploadZone
                            onUpload={handleFiles}
                            processing={processing}
                        />
                    ) : (
                        <SplitView
                            files={files}
                            onRemove={removeFile}
                            onProcess={processFiles}
                            onAddMore={handleFiles}
                            processing={processing}
                        />
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {showCreditDialog && (
                        <TextRemovalCredit
                            isOpen={showCreditDialog}
                            files={files}
                            credits={credits}
                            isPremium={user?.isPremium}
                            onClose={() => setShowCreditDialog(false)}
                            operationCost={operationCost}
                        />
                    )}
                </AnimatePresence>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                >
                    {/* <BeforeAfterTextRemove /> */}
                    <div className="py-16">
                        <h2 className="text-3xl font-bold text-center mb-12">
                            Frequently Asked Questions
                        </h2>
                        <TextRemoveFAQ />
                    </div>
                    <RemoveTextReviews />
                </motion.div>
            </div>
        </div>
    );
};

export default TextRemoval;