import React from 'react';
import { Zap, Clock,Crown } from 'lucide-react';

const CreditAlert = ({ currentCredits, required, isPremium }) => {
  // Helper to format time until midnight UTC
  const getTimeUntilReset = () => {
    const now = new Date();
    const tomorrow = new Date();
    tomorrow.setUTCHours(24, 0, 0, 0);
    const diffMs = tomorrow - now;
    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    return `${diffHrs}h ${diffMins}m`;
  };

  return (
    <div className="bg-gradient-to-r from-purple-50 to-blue-50 p-6 rounded-xl shadow-sm">
      <div className="flex flex-col items-center text-center">
        {isPremium ? (
          <>
            <Clock className="h-8 w-8 text-purple-500 mb-3" />
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              Credit Reset Soon
            </h3>
            <p className="text-gray-600 mb-2">
              You need {required} credits to merge faces.
              Currently have: {currentCredits} credits
            </p>
            <p className="text-sm text-purple-600">
              Your Premium credits will reset in {getTimeUntilReset()}
            </p>
          </>
        ) : (
          <>
            <Zap className="h-8 w-8 text-purple-500 mb-3" />
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              Need More Credits?
            </h3>
            <p className="text-gray-600 mb-2">
              You need {required} credits to merge faces.
              Currently have: {currentCredits} credits
            </p>
            <p className="text-sm text-purple-600 mb-4">
              Upgrade now to get 200 credits instantly!
            </p>
            <button
              onClick={() => window.location.href = '/checkout/premium'}
              className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center"
            >
              <Crown className="w-4 h-4 mr-2" />
              Upgrade to Premium
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CreditAlert;