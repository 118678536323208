import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate
} from 'react-router-dom';
import { Loader } from 'lucide-react';
import { AuthContext } from './contexts/AuthContext';
import CheckoutPage from './Components/Checkout/CheckoutPage';
import Header from './Components/Header';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import ConvertToJPG from './Components/ConvertToJPG';
import CropImage from './Components/CropImage';
import CompressImage from './Components/CompressImage';
import ResizeImage from './Components/ResizeImage';
import UpscaleImage from './Components/UpscaleImage';
import GoogleAnalytics from './Components/GoogleAnalytics';
import ConvertToPNG from './Components/ConvertToPNG';
import ConvertToPDF from './Components/ConvertToPDF';
import ConvertToWebP from './Components/ConvertToWebP';
import Footer from './Components/Footer';
import CheckoutSuccess from './Components/CheckoutSuccess';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy';
import TermsOfService from './Components/Pages/TermsOfService';
import CookiePolicy from './Components/Pages/CookiePolicy';
import About from './Components/Pages/About';
import Contact from './Components/Pages/Contact';
import Blog from './Components/Pages/Blog';
import UnderstandingImageFormats from './Components/Pages/BlogPosts/UnderstandingImageFormats';
import ImageOptimizationTips from './Components/Pages/BlogPosts/ImageOptimizationTips';
import FutureOfCompression from './Components/Pages/BlogPosts/FutureOfCompression';
import GADebugger from './utils/debugGA';
import MergeFaces from './Components/MergeFaces';
import AuthPage from './Components/Auth/AuthPage';
import AccountSettings from './Components/AccountSettings';
import VerifyEmail from './Components/Auth/VerifyEmail';
import { CreditsProvider } from './contexts/CreditContext';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import TextRemoval from './Components/TextRemoval';
// RedirectIfAuthenticated component to prevent authenticated users from accessing auth pages
const RedirectIfAuthenticated = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="w-8 h-8 animate-spin text-purple-600" />
      </div>
    );
  }

  if (user) {
    return <Navigate to={location.state?.returnUrl || '/'} replace />;
  }

  return children;
};

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="w-8 h-8 animate-spin text-purple-600" />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/auth" state={{ returnUrl: location.pathname }} replace />;
  }

  return children;
};

const RouteWrapper = ({ children }) => {
  const location = useLocation();
  console.log('Route rendered:', {
    pathname: location.pathname,
    component: children.type.name,
    state: location.state
  });
  return children;
};

const AppRoutes = () => (
  <Router>
    <AuthProvider>
      <CreditsProvider>
        <div className="min-h-screen bg-white flex flex-col">
          <GoogleAnalytics />
          <GADebugger />
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/image-convert-to-JPG" element={<ConvertToJPG />} />
              <Route path="/convert-to-JPG" element={
                <RouteWrapper>
                  <ConvertToJPG />
                </RouteWrapper>
              } />
              <Route path="/convert-to-png" element={<ConvertToPNG />} />
              <Route path="/convert-to-webp" element={<ConvertToWebP />} />
              <Route path="/convert-to-pdf" element={<ConvertToPDF />} />
              <Route path="/image-crop" element={<CropImage />} />
              <Route path="/image-compress" element={<CompressImage />} />
              <Route path="/image-resize" element={<ResizeImage />} />
              <Route path="/image-upscale" element={<UpscaleImage />} />
              <Route path="/merge-faces" element={<MergeFaces />} />
              <Route path="/remove-text" element={<TextRemoval />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/cookies" element={<CookiePolicy />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/understanding-image-formats" element={<UnderstandingImageFormats />} />
              <Route path="/blog/image-optimization-tips" element={<ImageOptimizationTips />} />
              <Route path="/blog/future-of-compression" element={<FutureOfCompression />} />
              
              {/* Auth Routes */}
              <Route path="/auth" element={
                <RedirectIfAuthenticated>
                  <AuthPage />
                </RedirectIfAuthenticated>
              } />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/forgot-password" element={
                <RedirectIfAuthenticated>
                  <ForgotPassword />
                </RedirectIfAuthenticated>
              } />
              <Route path="/reset-password" element={
                <RedirectIfAuthenticated>
                  <ResetPassword />
                </RedirectIfAuthenticated>
              } />
              
              {/* Protected Routes */}
              <Route path="/settings" element={
                <ProtectedRoute>
                  <RouteWrapper>
                    <AccountSettings />
                  </RouteWrapper>
                </ProtectedRoute>
              } />
              <Route path="/checkout/premium" element={
                <ProtectedRoute>
                  <RouteWrapper>
                    <CheckoutPage />
                  </RouteWrapper>
                </ProtectedRoute>
              } />
              <Route path="/checkout/success" element={
                <ProtectedRoute>
                  <RouteWrapper>
                    <CheckoutSuccess />
                  </RouteWrapper>
                </ProtectedRoute>
              } />
            </Routes>
          </main>
          <Footer />
        </div>
      </CreditsProvider>
    </AuthProvider>
  </Router>
);

export default AppRoutes;