import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Upload,
  AlertCircle,
  X,
  ChevronRight,
  Loader,
  Lock,
  Zap,
  Download
} from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import { useCredits } from '../contexts/CreditContext';
import DownloadFile from './DownloadFile';
import { trackEvent } from '../utils/analytics';
import { MergePromo } from './MergePromo';
import CreditAlert from './CreditAlert';

const MAX_DIMENSION = 2048;

const resizeImage = async (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const originalWidth = img.width;
      const originalHeight = img.height;

      // Check if resizing is needed
      if (originalWidth <= MAX_DIMENSION && originalHeight <= MAX_DIMENSION) {
        resolve(file);
        return;
      }

      // Calculate new dimensions
      let ratio = Math.min(MAX_DIMENSION / originalWidth, MAX_DIMENSION / originalHeight);
      const newWidth = Math.round(originalWidth * ratio);
      const newHeight = Math.round(originalHeight * ratio);

      // Create canvas and resize
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d');

      // Use better quality settings
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Convert to blob
      canvas.toBlob((blob) => {
        resolve(new File([blob], file.name, {
          type: 'image/jpeg',
          lastModified: Date.now()
        }));
      }, 'image/jpeg', 0.95);
    };

    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = URL.createObjectURL(file);
  });
};

const ImagePreview = ({ image, label, onRemove, isCompact = false }) => (
  <div className={`relative group transition-all duration-300 ease-in-out
    ${isCompact ? 'w-36 h-36 sm:w-48 sm:h-48' : 'w-40 h-40 sm:w-64 sm:h-64'}`}>
    <div className="relative w-full h-full flex items-center justify-center bg-gray-50 rounded-lg overflow-hidden border border-gray-200">
      <img src={image} alt={label} className="max-w-full max-h-full object-contain" />
      <div className="absolute top-2 left-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded">
        {label}
      </div>
      {onRemove && (
        <button
          onClick={onRemove}
          className="absolute top-2 right-2 bg-black bg-opacity-50 text-white p-1.5 rounded-full hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100"
          aria-label="Remove image"
        >
          <X className="w-4 h-4" />
        </button>
      )}
    </div>
  </div>
);

const UploadBox = ({ onClick, label, isCompact = false }) => (
  <div
    onClick={onClick}
    className={`transition-all duration-300 ease-in-out cursor-pointer 
      ${isCompact ? 'w-36 h-36 sm:w-48 sm:h-48' : 'w-40 h-40 sm:w-64 sm:h-64'}
      rounded-lg border-2 border-dashed border-gray-300 hover:border-purple-500 
      flex flex-col items-center justify-center p-4 sm:p-6`}
  >
    <Upload className="h-8 w-8 sm:h-12 sm:w-12 text-gray-400" />
    <p className="mt-2 sm:mt-4 text-xs sm:text-sm font-medium text-gray-900 text-center">{label}</p>
    <p className="mt-1 text-xs text-gray-500 hidden sm:block">Click to upload</p>
  </div>
);

const AuthAlert = () => (
  <div className="bg-gradient-to-r from-purple-50 to-blue-50 p-6 rounded-xl shadow-sm">
    <div className="flex flex-col items-center text-center">
      <Lock className="h-8 w-8 text-purple-500 mb-3" />
      <h3 className="text-lg font-semibold text-gray-900 mb-2">
        Sign in to Start Merging
      </h3>
      <p className="text-gray-600 mb-4">
        Create an account to get 50 free credits and start merging faces instantly.
      </p>
      <div className="flex gap-3">
        <button
          onClick={() => window.location.href = '/auth?isLogin=true'}
          className="px-4 py-2 text-purple-600 border border-purple-200 rounded-lg hover:bg-purple-50 transition-colors"
        >
          Sign In
        </button>
        <button
          onClick={() => window.location.href = '/auth?isLogin=false'}
          className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          Create Account
        </button>
      </div>
    </div>
  </div>
);



// First, let's add a new component for the upload/result section:
const PreviewSection = ({ mergedImage, onTryAgain, onDownload }) => (
  <div className="w-full max-w-2xl mx-auto text-center">
    <div className="mb-6">
      <div className="relative w-full aspect-video bg-gray-50 rounded-lg overflow-hidden border border-gray-200">
        <img src={URL.createObjectURL(mergedImage)} alt="Merged Result" className="w-full h-full object-contain" />
      </div>
    </div>
    <div className="flex justify-center gap-4">
      <button
        onClick={onTryAgain}
        className="px-6 py-3 text-purple-600 border border-purple-200 rounded-lg hover:bg-purple-50 transition-colors inline-flex items-center"
      >
        Try Again
      </button>
      <button
        onClick={onDownload}
        className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors inline-flex items-center"
      >
        <Download className="w-5 h-5 mr-2" />
        Download Result
      </button>
    </div>
  </div>
);

const UploadSection = ({ 
  state, 
  isCompact, 
  onUpload, 
  onRemove, 
  handleMerge,
  handleDownload,
  handleTryAgain, 
  buttonState, 
  user, 
  credits, 
  mergeInfo,
  onBack,
  faceImageInputRef,
  targetImageInputRef 
}) => (
  <div className="bg-white">
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="text-center max-w-2xl mx-auto mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-2">
          Face Merge
        </h1>
        <p className="text-gray-600">
          Upload a source face and target image to merge them using AI technology.
        </p>
      </div>

      {state.showDownload ? (
        <DownloadFile
          files={[state.downloadFile]}
          onBack={onBack}
          toolName="Face Merge"
          toolIdentifier="face-merge"
        />
      ) : (
        <div className="relative">
          <div className="flex flex-col items-center">
            <div className="flex flex-row items-start justify-center gap-4 sm:gap-8 mb-6">
              <div className="relative">
                {!state.faceImage ? (
                  <UploadBox 
                    onClick={() => faceImageInputRef.current?.click()}
                    label="Upload Source Face"
                    isCompact={isCompact}
                  />
                ) : (
                  <ImagePreview 
                    image={state.faceImage}
                    label="Source Face"
                    onRemove={() => onRemove('faceImage')}
                    isCompact={isCompact}
                  />
                )}
                {state.isProcessing && state.faceImage && (
                  <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center">
                    <Loader className="w-6 h-6 text-white animate-spin" />
                  </div>
                )}
              </div>

              <div className="relative">
                {!state.targetImage ? (
                  <UploadBox 
                    onClick={() => targetImageInputRef.current?.click()}
                    label="Upload Target Image"
                    isCompact={isCompact}
                  />
                ) : (
                  <ImagePreview 
                    image={state.targetImage}
                    label="Target Image"
                    onRemove={() => onRemove('targetImage')}
                    isCompact={isCompact}
                  />
                )}
                {state.isProcessing && state.targetImage && (
                  <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center">
                    <Loader className="w-6 h-6 text-white animate-spin" />
                  </div>
                )}
              </div>
            </div>

            {state.mergedBlob && (
              <PreviewSection
                mergedImage={state.mergedBlob}
                onTryAgain={handleTryAgain}
                onDownload={handleDownload}
              />
            )}

            {state.faceImage && state.targetImage && !state.mergedBlob && (
              <div className="w-full max-w-md mb-8">
                {!user ? (
                  <AuthAlert />
                ) : credits < (mergeInfo?.credit_info?.credits_required || 10) && (
                  <CreditAlert 
                    currentCredits={credits}
                    required={mergeInfo?.credit_info?.credits_required || 10}
                    isPremium={user.isPremium}
                  />
                )}
              </div>
            )}

            <AnimatePresence>
              {state.error && (
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="w-full max-w-2xl mb-6"
                >
                  <div className="bg-red-50 text-red-800 p-4 rounded-lg flex items-start">
                    <AlertCircle className="w-5 h-5 mt-0.5 mr-2" />
                    <span>{state.error}</span>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {state.faceImage && state.targetImage && !state.mergedBlob && (
              <motion.button
                onClick={handleMerge}
                disabled={buttonState.disabled}
                className={`px-6 py-3 rounded-full shadow-lg inline-flex items-center justify-center
                  ${buttonState.disabled
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-purple-500 hover:bg-purple-600'
                  } text-white transition-all duration-300`}
                whileHover={!buttonState.disabled ? { scale: 1.05 } : {}}
                whileTap={!buttonState.disabled ? { scale: 0.95 } : {}}
              >
                {buttonState.text}
                {buttonState.icon}
              </motion.button>
            )}
          </div>
        </div>
      )}

      <input
        type="file"
        ref={faceImageInputRef}
        onChange={(e) => e.target.files?.[0] && onUpload(e.target.files[0], 'faceImage')}
        accept="image/*"
        className="hidden"
      />
      <input
        type="file"
        ref={targetImageInputRef}
        onChange={(e) => e.target.files?.[0] && onUpload(e.target.files[0], 'targetImage')}
        accept="image/*"
        className="hidden"
      />
    </div>
  </div>
);


const MergeFaces = () => {
  const { user } = useContext(AuthContext);
  const { credits, refreshCredits } = useCredits();
  const navigate = useNavigate();
  const [mergeInfo] = useState(null);
  const [state, setState] = useState({
    faceImage: null,
    targetImage: null,
    processedFaceImage: null,
    processedTargetImage: null,
    isProcessing: false,
    error: null,
    nextReset: null,
    showDownload: false,
    downloadFile: null,
    mergedBlob: null,
  });

  const faceImageInputRef = useRef(null);
  const targetImageInputRef = useRef(null);

  // Retain existing useEffect for fetching mergeInfo...

  const handleFile = async (file, type) => {
    try {
      setState(prev => ({
        ...prev,
        error: null,
        isProcessing: true
      }));

      // Create preview URL
      const previewUrl = URL.createObjectURL(file);

      // Process image if needed
      let processedFile = file;
      const img = new Image();
      
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = previewUrl;
      });

      if (img.width > MAX_DIMENSION || img.height > MAX_DIMENSION) {
        processedFile = await resizeImage(file);
      }

      setState(prev => ({
        ...prev,
        [type]: previewUrl,
        [`processed${type.charAt(0).toUpperCase() + type.slice(1)}`]: processedFile,
        isProcessing: false,
        error: null,
        showDownload: false,
        downloadFile: null
      }));

      trackEvent('face_merge_upload', {
        file_type: type,
        file_size: processedFile.size,
        original_size: file.size,
        was_resized: processedFile !== file,
        user_id: user?.email || 'anonymous'
      });
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: 'Failed to process image. Please try again.',
        isProcessing: false
      }));
    }
  };

  const handleMerge = async () => {
    if (!user) {
      navigate('/auth', {
        state: {
          returnUrl: '/merge-faces',
          message: 'Please sign in to use face merge'
        }
      });
      return;
    }

    const requiredCredits = mergeInfo?.credit_info?.credits_required || 10;
    if (credits < requiredCredits) {
      setState(prev => ({
        ...prev,
        error: 'Insufficient credits for face merge'
      }));
      return;
    }

    setState(prev => ({ ...prev, isProcessing: true, error: null }));

    try {
      const formData = new FormData();
      formData.append('face_image', state.processedFaceImage || state.faceImage);
      formData.append('target_image', state.processedTargetImage || state.targetImage);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/merge-faces`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json();
        
        if (response.status === 401) {
          navigate('/auth', {
            state: {
              returnUrl: '/merge-faces',
              message: 'Please sign in to use face merge'
            }
          });
          return;
        }
        
        throw new Error(errorData.detail || 'Face merge failed');
      }

      const blob = await response.blob();
      await refreshCredits();

      setState(prev => ({
        ...prev,
        mergedBlob: blob,
        isProcessing: false,
      }));

    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error.message,
        isProcessing: false,
      }));
    }
  };
  const handleDownload = () => {
    setState(prev => ({
      ...prev,
      showDownload: true,
      downloadFile: {
        name: `ilikeimg_merged_${new Date().toISOString().replace(/[:.]/g, '')}.png`,
        blob: state.mergedBlob
      }
    }));
  };

  const handleTryAgain = () => {
    setState(prev => ({
      ...prev,
      mergedBlob: null,
      showDownload: false,
      downloadFile: null,
    }));
  };
  const handleRemove = (type) => {
    setState(prev => ({
      ...prev,
      [type]: null,
      [`processed${type.charAt(0).toUpperCase() + type.slice(1)}`]: null
    }));
  };

  const resetState = () => {
    setState({
      faceImage: null,
      targetImage: null,
      processedFaceImage: null,
      processedTargetImage: null,
      isProcessing: false,
      error: null,
      nextReset: null,
      showDownload: false,
      downloadFile: null,
    });
  };



  const getButtonState = () => {
    if (!user) {
      return {
        disabled: true,
        text: 'Sign in to Merge',
        icon: <Lock className="w-5 h-5 mr-2" />
      };
    }

    if (state.isProcessing) {
      return {
        disabled: true,
        text: 'Processing...',
        icon: <Loader className="w-5 h-5 mr-2 animate-spin" />
      };
    }

    const requiredCredits = mergeInfo?.credit_info?.credits_required || 10;
    if (credits < requiredCredits) {
      return {
        disabled: true,
        text: 'Insufficient Credits',
        icon: <Zap className="w-5 h-5 mr-2" />
      };
    }

    return {
      disabled: false,
      text: 'Merge Faces',
      icon: <ChevronRight className="w-5 h-5 ml-2" />
    };
  };

  const buttonState = getButtonState();

  return (
    <>
      <Helmet>
        <title>AI Face Merge - Swap Faces Online | ilikeimg</title>
        <meta name="description" content="Merge faces using advanced AI technology. Upload source and target images to create seamless face swaps." />
        
        <meta property="og:title" content="AI Face Merge - Face Swap Online" />
        <meta property="og:description" content="Create seamless face swaps with our advanced AI technology. Fast, easy, and secure." />
        <meta property="og:image" content={`${window.location.origin}/social/face-merge-preview.jpg`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <UploadSection
          state={state}
          isCompact={state.isProcessing}
          onUpload={handleFile}
          onRemove={handleRemove}
          handleMerge={handleMerge}
          handleDownload={handleDownload}
          handleTryAgain={handleTryAgain}
          buttonState={buttonState}
          user={user}
          credits={credits}
          mergeInfo={mergeInfo}
          onBack={resetState}
          faceImageInputRef={faceImageInputRef}
          targetImageInputRef={targetImageInputRef}
        />

        <div className="flex-grow">
          <MergePromo />
        </div>
      </div>
    </>
  );
};

export default MergeFaces;