import React from 'react';
import { Star
    // ArrowRight, CheckCircle, Sparkles
 } from 'lucide-react';

// const FeatureShowcase = () => (
//   <div className="py-16 bg-gradient-to-b from-white to-purple-50">
//     <div className="max-w-7xl mx-auto px-4">
//       <div className="text-center mb-12">
//         <h2 className="text-3xl font-bold mb-4">
//           How Face Merging Works
//         </h2>
//         <p className="text-gray-600 max-w-2xl mx-auto">
//           Our AI-powered technology seamlessly combines faces while maintaining natural features and expressions
//         </p>
//       </div>

//       <div className="flex flex-col md:flex-row items-center justify-center gap-8 mb-16">
//         {/* Source Image */}
//         <div className="relative">
//           <img 
//             src="/demo/source-face.jpg" 
//             alt="Source Face" 
//             className="w-48 h-48 object-cover rounded-lg shadow-lg"
//           />
//           <div className="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded">
//             Source Face
//           </div>
//         </div>

//         <ArrowRight className="w-8 h-8 text-purple-500 hidden md:block" />
//         <div className="hidden md:flex items-center justify-center h-8">
//           <div className="w-2 h-2 bg-purple-500 rounded-full mx-1" />
//           <div className="w-2 h-2 bg-purple-500 rounded-full mx-1" />
//           <div className="w-2 h-2 bg-purple-500 rounded-full mx-1" />
//         </div>

//         {/* Target Image */}
//         <div className="relative">
//           <img 
//             src="/demo/target-image.jpg" 
//             alt="Target Image" 
//             className="w-48 h-48 object-cover rounded-lg shadow-lg"
//           />
//           <div className="absolute bottom-2 left-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded">
//             Target Image
//           </div>
//         </div>

//         <ArrowRight className="w-8 h-8 text-purple-500 hidden md:block" />
//         <div className="hidden md:flex items-center justify-center h-8">
//           <div className="w-2 h-2 bg-purple-500 rounded-full mx-1" />
//           <div className="w-2 h-2 bg-purple-500 rounded-full mx-1" />
//           <div className="w-2 h-2 bg-purple-500 rounded-full mx-1" />
//         </div>

//         {/* Result Image */}
//         <div className="relative">
//           <div className="absolute -top-4 -right-4 bg-purple-500 text-white text-xs px-2 py-1 rounded-full flex items-center">
//             <Sparkles className="w-3 h-3 mr-1" />
//             Result
//           </div>
//           <img 
//             src="/demo/result.jpg" 
//             alt="Result" 
//             className="w-48 h-48 object-cover rounded-lg shadow-lg ring-4 ring-purple-200"
//           />
//         </div>
//       </div>

//       {/* Features Grid */}
//       <div className="grid md:grid-cols-3 gap-8 mb-16">
//         {[
//           {
//             title: 'Smart Face Detection',
//             description: 'Advanced AI detects and analyzes facial features for perfect alignment'
//           },
//           {
//             title: 'Natural Blending',
//             description: 'Seamless integration maintains natural skin tones and textures'
//           },
//           {
//             title: 'Expression Preservation',
//             description: 'Keeps original expressions while adapting to the target image'
//           }
//         ].map((feature, index) => (
//           <div key={index} className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
//             <CheckCircle className="w-8 h-8 text-purple-500 mb-4" />
//             <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
//             <p className="text-gray-600">{feature.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   </div>
// );

const ReviewSection = () => (
  <div className="py-16 bg-white">
    <div className="max-w-7xl mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12">
        What Users Say
      </h2>
      
      <div className="grid md:grid-cols-3 gap-6">
        {[
          {
            name: "Sarah M.",
            role: "Photographer",
            text: "The face merging quality is incredible. I use it for creative portraits!",
            rating: 5
          },
          {
            name: "David K.",
            role: "Digital Artist",
            text: "Perfect for concept art and visualization. Fast and accurate results.",
            rating: 5
          },
          {
            name: "Emily R.",
            role: "Content Creator",
            text: "Game-changing tool for my creative projects. Love the natural results!",
            rating: 5
          }
        ].map((review, index) => (
          <div key={index} className="bg-gray-50 p-6 rounded-xl">
            <div className="flex items-center mb-1">
              {[...Array(review.rating)].map((_, i) => (
                <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" />
              ))}
            </div>
            <p className="text-gray-700 mb-4">"{review.text}"</p>
            <div>
              <p className="font-medium">{review.name}</p>
              <p className="text-sm text-gray-500">{review.role}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const FAQSection = () => (
  <div className="py-16 bg-gray-50">
    <div className="max-w-3xl mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12">
        Frequently Asked Questions
      </h2>
      
      <div className="space-y-6">
        {[
          {
            q: "How does face merging work?",
            a: "Our AI technology analyzes facial features and seamlessly combines them while maintaining natural appearance and expressions."
          },
          {
            q: "What image formats are supported?",
            a: "We support JPEG, PNG, and WebP formats. Images are automatically optimized for best results."
          },
          {
            q: "Are my images kept private?",
            a: "Yes! All uploaded images are processed securely and automatically deleted after processing. We never store your images."
          },
          {
            q: "Can I merge multiple faces?",
            a: "Currently, we support merging one source face with one target image at a time for the best quality results."
          }
        ].map((faq, index) => (
          <div key={index} className="bg-white rounded-lg p-6 shadow-sm">
            <h3 className="text-lg font-medium text-gray-900 mb-2">{faq.q}</h3>
            <p className="text-gray-600">{faq.a}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export const MergePromo = () => (
  <>
    {/* <FeatureShowcase /> */}
    <ReviewSection />
    <FAQSection />
  </>
);