import React from 'react';
import { Helmet } from 'react-helmet';

export const CookiePolicy = () => {
  return (
    <>
      <Helmet>
        <title>Cookie Policy - ilikeimg</title>
        <meta name="description" content="Learn about how ilikeimg uses cookies and similar technologies" />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Cookie Policy</h1>
        <div className="prose prose-purple max-w-none">
          <p className="text-gray-600 mb-4">Last updated: November 26, 2024</p>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">What Are Cookies</h2>
            <p className="text-gray-600">
              Cookies are small text files stored on your device when you visit our website. 
              They help us provide and improve our services by enabling certain functionalities 
              and collecting anonymous usage data.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Types of Cookies We Use</h2>
            <div className="text-gray-600">
              <h3 className="font-medium mt-4">Essential Cookies</h3>
              <p className="mb-4">
                Required for basic website functionality. These cookies enable core features like 
                authentication, session management, and security.
              </p>

              <h3 className="font-medium mt-4">Analytics Cookies</h3>
              <p className="mb-4">
                Help us understand how visitors interact with our website using Google Analytics. 
                These cookies collect anonymous data about page visits and user behavior.
              </p>

              <h3 className="font-medium mt-4">Preference Cookies</h3>
              <p className="mb-4">
                Store your preferences and settings to enhance your experience, such as language 
                selection and theme preferences.
              </p>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Cookie Management</h2>
            <p className="text-gray-600">
              You can control cookies through your browser settings. Most browsers allow you to 
              block or delete cookies. However, blocking essential cookies may affect website 
              functionality.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Third-Party Cookies</h2>
            <p className="text-gray-600">
              We use services from Google (Analytics, OAuth) and Stripe (payments) that may set 
              their own cookies. These third-party services have their own privacy policies and 
              cookie practices.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;