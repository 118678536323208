// src/utils/passwordUtils.js

export const PASSWORD_REQUIREMENTS = {
    minLength: 8,
    requireNumbers: true,
    requireSpecialChars: true,
    requireUppercase: true,
  };
  
  export const validatePassword = (password) => {
    const errors = [];
  
    if (password.length < PASSWORD_REQUIREMENTS.minLength) {
      errors.push(`Password must be at least ${PASSWORD_REQUIREMENTS.minLength} characters long`);
    }
  
    if (PASSWORD_REQUIREMENTS.requireNumbers && !/\d/.test(password)) {
      errors.push('Password must contain at least one number');
    }
  
    if (PASSWORD_REQUIREMENTS.requireSpecialChars && !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Password must contain at least one special character');
    }
  
    if (PASSWORD_REQUIREMENTS.requireUppercase && !/[A-Z]/.test(password)) {
      errors.push('Password must contain at least one uppercase letter');
    }
  
    return {
      isValid: errors.length === 0,
      errors
    };
  };
  
  export const getPasswordStrength = (password) => {
    let strength = 0;
    
    // Length contribution
    strength += Math.min(password.length / 8, 1) * 0.25;
    
    // Character variety contribution
    if (/[0-9]/.test(password)) strength += 0.25;
    if (/[a-z]/.test(password)) strength += 0.25;
    if (/[A-Z]/.test(password)) strength += 0.25;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) strength += 0.25;
    
    return {
      score: strength,
      label: strength < 0.3 ? 'Weak' :
             strength < 0.6 ? 'Moderate' :
             strength < 0.8 ? 'Strong' : 'Very Strong',
      color: strength < 0.3 ? 'red' :
             strength < 0.6 ? 'orange' :
             strength < 0.8 ? 'green' : 'emerald'
    };
  };