import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Mail,
  Loader,
  ArrowLeft,
  CheckCircle,
  AlertCircle,
  RefreshCw
} from 'lucide-react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [rateLimitInfo, setRateLimitInfo] = useState(null);
  const [canRetry, setCanRetry] = useState(true);

  useEffect(() => {
    // Reset error when email changes
    if (error) {
      setError(null);
      setRateLimitInfo(null);
    }
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setRateLimitInfo(null);
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email })
        }
      );

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 429) {
          setRateLimitInfo(data.detail);
          setCanRetry(false);
          throw new Error('Too many reset attempts. Please try again later.');
        }
        throw new Error(data.detail || 'Failed to process request');
      }

      setSuccess(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Check your email
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              If an account exists with {email}, you will receive password reset instructions.
            </p>
          </div>
          <div className="mt-4 text-center space-y-4">
            <p className="text-sm text-gray-500">
              Don't see the email? Check your spam folder.
            </p>
            <button
              onClick={() => {
                setSuccess(false);
                setEmail('');
              }}
              className="text-sm font-medium text-purple-600 hover:text-purple-500 inline-flex items-center"
            >
              <RefreshCw className="w-4 h-4 mr-2" />
              Try another email
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Reset Password | ilikeimg</title>
      </Helmet>

      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Reset your password
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Enter your email address and we'll send you instructions to reset your password.
            </p>
          </div>

          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400 mt-0.5" />
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    {error}
                  </h3>
                  {rateLimitInfo && (
                    <div className="mt-2 text-sm text-red-700">
                      <p>{rateLimitInfo.message}</p>
                      {rateLimitInfo.retry_after && (
                        <p className="mt-1">Please try again {rateLimitInfo.retry_after}</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-lg relative block w-full pl-10 pr-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading || !canRetry}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading || !canRetry || !email}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <Loader className="w-5 h-5 animate-spin" />
                ) : (
                  'Send reset instructions'
                )}
              </button>
            </div>

            <div className="flex items-center justify-center space-x-4">
              <Link
                to="/auth"
                className="text-sm font-medium text-purple-600 hover:text-purple-500 flex items-center"
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Back to login
              </Link>
            </div>
          </form>

          <div className="mt-6 text-center">
            <p className="text-xs text-gray-500">
              By clicking "Send reset instructions", you agree to our{' '}
              <Link to="/terms" className="text-purple-600 hover:text-purple-500">
                Terms
              </Link>
              {' '}and{' '}
              <Link to="/privacy" className="text-purple-600 hover:text-purple-500">
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;