import React from 'react';
import { Upload, Lock, Zap, ShieldCheck, Image as ImageIcon, AlertCircle } from 'lucide-react';
import { motion } from 'framer-motion';

const FeatureCard = ({ icon: Icon, title, description }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        className="bg-gray-50 rounded-xl p-6 border border-gray-100 hover:border-purple-100 
                   hover:bg-white transition-all duration-300"
    >
        <div className="rounded-lg w-12 h-12 bg-purple-50 flex items-center justify-center mb-4">
            <Icon className="w-6 h-6 text-purple-500" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 mb-2">{title}</h3>
        <p className="text-sm text-gray-600">{description}</p>
    </motion.div>
);

const UploadSection = ({ 
    isDragging, 
    onFileSelect, 
    dropZoneRef, 
    user, 
    formatFileSize, 
    MAX_FILE_SIZE,
    validationError 
}) => {
    const features = [
        {
            icon: Zap,
            title: '4x Resolution Boost',
            description: 'Transform your images with our advanced AI upscaling technology'
        },
        {
            icon: ImageIcon,
            title: 'Preserve Quality',
            description: 'Maintain image quality while significantly increasing resolution'
        },
        {
            icon: ShieldCheck,
            title: 'Secure Processing',
            description: 'Your images are processed securely and never stored'
        }
    ];

    return (
        <div className="space-y-12">
            {/* Hero Section */}
            <div className="text-center px-4 py-8">
                <motion.h1
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-4xl sm:text-5xl font-bold text-gray-900 mb-6"
                >
                    AI-Powered{' '}
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
                        Image Upscaling
                    </span>
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto"
                >
                    Transform your images with advanced super-resolution technology.
                    Get crystal-clear results with up to 4x resolution enhancement.
                </motion.p>

                {!user && (
                    <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className="text-sm text-purple-600 flex items-center justify-center"
                    >
                        <Lock className="w-4 h-4 mr-1" />
                        Sign in required for upscaling
                    </motion.p>
                )}
            </div>

            {/* Upload Box */}
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="max-w-3xl mx-auto px-4"
            >
                <div
                    ref={dropZoneRef}
                    className={`group relative p-8 sm:p-12 flex flex-col items-center justify-center 
                             bg-white border-2 border-dashed rounded-xl transition-all duration-300
                             ${isDragging 
                                ? 'border-purple-500 bg-purple-50 scale-102' 
                                : validationError
                                    ? 'border-red-300 bg-red-50/50'
                                    : 'border-gray-300 hover:border-purple-300'}`}
                >
                    <div className={`absolute inset-0 rounded-xl transition-opacity duration-300
                                  bg-gradient-to-b from-transparent to-purple-50/30
                                  ${isDragging ? 'opacity-100' : 'opacity-0'}
                                  group-hover:opacity-100`} 
                    />

                    <div className="relative text-center">
                        <motion.div
                            whileHover={{ scale: validationError ? 1 : 1.05 }}
                            whileTap={{ scale: validationError ? 1 : 0.95 }}
                            className={`mx-auto w-16 h-16 rounded-2xl flex items-center justify-center mb-4
                                      transition-colors duration-300
                                      ${validationError 
                                        ? 'bg-red-50' 
                                        : isDragging 
                                            ? 'bg-purple-100'
                                            : 'bg-purple-50 group-hover:bg-purple-100'}`}
                        >
                            {validationError ? (
                                <AlertCircle className="w-8 h-8 text-red-500" />
                            ) : (
                                <Upload className={`w-8 h-8 transition-colors duration-300
                                                  ${isDragging 
                                                    ? 'text-purple-600' 
                                                    : 'text-purple-500 group-hover:text-purple-600'}`} 
                                />
                            )}
                        </motion.div>

                        {validationError ? (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ type: "spring", stiffness: 500, damping: 30 }}
                            >
                                <h3 className="text-xl font-medium text-red-600 mb-2">
                                    {validationError}
                                </h3>
                                <p className="text-red-500 mb-4">Please try again with a different file</p>
                            </motion.div>
                        ) : (
                            <>
                                <h3 className="text-xl font-medium text-gray-900 mb-2">
                                    {isDragging ? 'Release to Upload' : 'Drag & drop your image here'}
                                </h3>
                                <p className="text-gray-500 mb-4">or</p>
                            </>
                        )}

                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={onFileSelect}
                            className={`px-6 py-3 rounded-xl transition-all duration-300 
                                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50
                                      ${validationError 
                                        ? 'bg-red-500 hover:bg-red-600 text-white focus:ring-red-500' 
                                        : 'bg-purple-500 hover:bg-purple-600 text-white focus:ring-purple-500'
                                      } shadow-sm hover:shadow-md`}
                        >
                            {validationError ? 'Try Another File' : 'Browse Files'}
                        </motion.button>

                        <p className="mt-4 text-sm text-gray-500">
                            Supported formats: JPEG, PNG, WebP (Max {formatFileSize(MAX_FILE_SIZE)})
                        </p>
                    </div>
                </div>
            </motion.div>

            {/* Features Grid */}
            <div className="max-w-6xl mx-auto px-4">
                <div className="grid md:grid-cols-3 gap-6">
                    {features.map((feature) => (
                        <FeatureCard
                            key={feature.title}
                            icon={feature.icon}
                            title={feature.title}
                            description={feature.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UploadSection;