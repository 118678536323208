import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as LucideIcons from 'lucide-react';
import { AuthContext } from './contexts/AuthContext';
import {
  UserCircle,
  Mail,
  Lock,
  Star,
  Eye,
  EyeOff,
} from 'lucide-react';

export const TOOL_PATHS = {
  ai: {
    upscale: '/image-upscale',
    mergeFaces: '/merge-faces', 
    removeTex:'remove-text'
  },
  convert: {
    jpg: '/convert-to-JPG',
    png: '/convert-to-png',
    webp: '/convert-to-webp',
    pdf: '/convert-to-pdf'
  },
  edit: {
    crop: '/image-crop',
    compress: '/image-compress',
    resize: '/image-resize'
  }
};

// Reuse the Input component from AuthPage
const Input = ({ icon: Icon, error, success, loading, ...props }) => (
  <div className="relative">
    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <Icon className={`h-5 w-5 ${loading ? 'text-gray-400' :
        error ? 'text-red-400' :
          success ? 'text-green-400' :
            'text-gray-400'}`} 
      />
    </div>
    <input
      {...props}
      className={`block w-full pl-10 pr-10 py-2.5 text-sm
        border ${loading ? 'border-gray-300' :
          error ? 'border-red-300' :
            success ? 'border-green-300' :
              'border-gray-300'
        } 
        rounded-xl placeholder-gray-400 
        focus:outline-none focus:ring-2 
        ${loading ? 'focus:ring-purple-500' :
          error ? 'focus:ring-red-500' :
            success ? 'focus:ring-green-500' :
              'focus:ring-purple-500'
        }
        focus:border-transparent transition-all duration-150
        disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed`}
    />
  </div>
);

const ToolCard = ({ to, icon, title, description }) => {
  const navigate = useNavigate();

  return (
    <div 
      onClick={() => navigate(to)} 
      className="cursor-pointer" 
      role="button" 
      aria-label={`Use ${title} tool`}
    >
      <div className="bg-white p-6 rounded-xl transition-all duration-300 hover:shadow-md border border-gray-100 hover:border-purple-100">
        <div className="flex flex-col items-center">
          <div className="mb-4 p-3 rounded-lg bg-purple-50 text-purple-500">
            {icon}
          </div>
          <h2 className="text-lg font-medium text-gray-900 mb-2">{title}</h2>
          <p className="text-sm text-gray-500 text-center">{description}</p>
        </div>
      </div>
    </div>
  );
};



const AuthModal = ({ isOpen, onClose, onSwitch }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    identifier: '',
    email: '',
    username: '',
    password: ''
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl p-6 max-w-md w-full relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4"
        >
          <LucideIcons.X className="w-6 h-6" />
        </button>
        
        <h2 className="text-2xl font-bold mb-6">
          {isLogin ? 'Sign In' : 'Create Account'}
        </h2>

        <form className="space-y-4">
          {!isLogin && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Username
                </label>
                <Input
                  icon={UserCircle}
                  type="text"
                  value={formData.username}
                  onChange={(e) => setFormData({...formData, username: e.target.value})}
                  placeholder="Choose a username"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <Input
                  icon={Mail}
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                  placeholder="you@example.com"
                />
              </div>
            </>
          )}
          
          {isLogin && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email or Username
              </label>
              <Input
                icon={Mail}
                type="text"
                value={formData.identifier}
                onChange={(e) => setFormData({...formData, identifier: e.target.value})}
                placeholder="Email or username"
              />
            </div>
          )}
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <div className="relative">
              <Input
                icon={Lock}
                type={showPassword ? "text" : "password"}
                value={formData.password}
                onChange={(e) => setFormData({...formData, password: e.target.value})}
                placeholder="••••••••"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-purple-600 text-white py-2.5 px-4 rounded-xl
                     hover:bg-purple-700 focus:ring-2 focus:ring-offset-2 
                     focus:ring-purple-500 transition-colors"
          >
            {isLogin ? 'Sign In' : 'Create Account'}
          </button>
        </form>

        <p className="mt-4 text-center text-sm text-gray-600">
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <button 
            onClick={() => setIsLogin(!isLogin)}
            className="text-purple-600 hover:text-purple-700 font-medium"
          >
            {isLogin ? 'Sign Up' : 'Sign In'}
          </button>
        </p>
      </div>
    </div>
  );
};

const App = () => {
  const { user } = useContext(AuthContext);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const reviews = [
    {author: "Sarah Chen", role: "Graphic Designer", content: "The AI upscaling is incredible. My old photos look amazing now!", rating: 5},
    {author: "Mike Johnson", role: "Digital Artist", content: "These tools have revolutionized my workflow. Worth every penny.", rating: 5},
    {author: "Lisa Rodriguez", role: "Photographer", content: "The batch processing saves me hours every week.", rating: 5},
    {author: "David Kim", role: "Web Developer", content: "Best image optimization tools I've found. The API is fantastic.", rating: 4},
    {author: "Emma Wilson", role: "Social Media Manager", content: "Perfect for quick image edits and format conversions.", rating: 5},
    {author: "Tom Mitchell", role: "UI Designer", content: "The AI features are game-changing. Highly recommend!", rating: 5},
    {author: "Julia Lee", role: "Content Creator", content: "Simple to use yet powerful. Exactly what I needed.", rating: 5},
    {author: "Alex Turner", role: "Product Designer", content: "The face swap feature is incredibly accurate!", rating: 5}
  ];

  const tools = {
    ai: [
      {to: TOOL_PATHS.ai.upscale, icon: <LucideIcons.ImageUp className="w-6 h-6" />, title: 'AI Upscale', description: 'Enhance image quality with AI'},
      {to: TOOL_PATHS.ai.mergeFaces, icon: <LucideIcons.UserSquare2 className="w-6 h-6" />, title: 'Face Swap', description: 'AI-powered face swapping'},
      {to: TOOL_PATHS.ai.removeTex, icon: <LucideIcons.Eraser className="w-6 h-6" />, title: 'Remove Text', description: 'Remove watermarks and text from images'},

    ],
    basic: [
      {to: TOOL_PATHS.convert.jpg, icon: <LucideIcons.Image className="w-6 h-6" />, title: 'JPG Convert', description: 'Convert to JPEG format'},
      {to: TOOL_PATHS.edit.crop, icon: <LucideIcons.Crop className="w-6 h-6" />, title: 'Crop', description: 'Adjust image dimensions'},
      {to: TOOL_PATHS.edit.compress, icon: <LucideIcons.Minimize2 className="w-6 h-6" />, title: 'Compress', description: 'Reduce file size'},
      {to: TOOL_PATHS.edit.resize, icon: <LucideIcons.Move className="w-6 h-6" />, title: 'Resize', description: 'Change dimensions'}
    ]
  };

  return (
    <>
      <Helmet>
        <title>ilikeimg - AI-Powered Image Tools | Free Online Photo Editor</title>
        <meta name="description" content="Transform your images with AI-powered tools. Free online image editor featuring upscaling, face swap, format conversion, and more." />
      </Helmet>

      <AuthModal 
        isOpen={showAuthModal} 
        onClose={() => setShowAuthModal(false)}
      />

      <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-7xl mx-auto px-4 py-12">
          {user && (
            <div className="mb-8 text-center">
              <h2 className="text-2xl font-bold text-gray-900">Welcome back, {user.username}! 👋</h2>
              <p className="text-gray-600">You have {user.credits} credits available today</p>
            </div>
          )}

          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              AI-Powered Image Tools,
              <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent"> Free to Use</span>
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Transform your images with advanced AI technology and essential editing tools.
            </p>
          </div>

          <div className="mb-16">
            <h2 className="text-2xl font-bold mb-6 text-center">AI Tools</h2>
            <div className="grid md:grid-cols-2 gap-4">
              {tools.ai.map((tool) => (
                <ToolCard key={tool.to} {...tool} onAuthRequired={() => setShowAuthModal(true)} />
              ))}
            </div>
          </div>

          <div className="mb-16">
          <h2 className="text-2xl font-bold mb-6 text-center">Essential Tools</h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {tools.basic.map((tool) => (
                <ToolCard key={tool.to} {...tool} />
              ))}
            </div>
          </div>

          <div className="mb-16">
            <h2 className="text-2xl font-bold text-center mb-12">What Our Users Say</h2>
            <div className="grid md:grid-cols-3 gap-6 max-w-7xl mx-auto px-4">
              {reviews.slice(0, 3).map((review, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-xl">
                  <div className="flex items-center mb-1">
                    {[...Array(review.rating)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" />
                    ))}
                  </div>
                  <p className="text-gray-700 mb-4">"{review.content}"</p>
                  <div>
                    <p className="font-medium">{review.author}</p>
                    <p className="text-sm text-gray-500">{review.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-16">
            <h2 className="text-2xl font-bold mb-6 text-center">Frequently Asked Questions</h2>
            <div className="max-w-3xl mx-auto space-y-4">
              {[
                {q: "What are credits?", a: "Credits are used for processing images. They reset daily and vary based on your plan."},
                {q: "How does AI upscaling work?", a: "Our AI technology analyzes your image to add detail and enhance quality while maintaining natural appearance."},
                {q: "Can I cancel anytime?", a: "Yes, you can cancel your premium subscription at any time. You'll retain access until the end of your billing period."},
                {q: "Is my data secure?", a: "We use enterprise-grade encryption and automatically delete your images after processing."}
              ].map((item, i) => (
                <details key={i} className="bg-white rounded-xl p-4 border border-gray-200">
                  <summary className="font-medium text-gray-900 cursor-pointer">{item.q}</summary>
                  <p className="mt-2 text-gray-600">{item.a}</p>
                </details>
              ))}
            </div>
          </div>


          {!user && (
            <div className="text-center">
              <div className="inline-flex items-center bg-gradient-to-r from-purple-50 to-blue-50 px-6 py-3 rounded-lg">
                <LucideIcons.Sparkles className="w-5 h-5 text-purple-500 mr-2" />
                <span className="text-gray-700">
                  Get started with free tools today
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default App;