import React from 'react';
import { AlertCircle, Loader, Upload } from 'lucide-react';
import { formatFileSize, MAX_FILE_SIZE } from '../utils/upscaleUtils';

export const ErrorMessage = ({ message }) => (
    <div className="flex items-start space-x-2 bg-red-50 text-red-800 p-4 rounded-lg">
        <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
        <span className="text-sm">{message}</span>
    </div>
);

export const SettingsDrawer = ({ 
    isOpen, 
    imageInfo, 
    settings,
    onSettingsChange,
    isProcessing 
}) => (
    <div className={`fixed top-0 right-0 w-64 bg-white border-l border-gray-200 transform transition-transform duration-300 ease-in-out 
                    z-30 h-full ${isOpen ? 'translate-x-0' : 'translate-x-full'} 
                    sm:translate-x-0 sm:relative sm:transform-none sm:z-0`}>
        <div className="p-4 h-full flex flex-col overflow-y-auto">
            <div className="mb-4">
                <h3 className="text-lg font-medium">Upscale Settings</h3>
            </div>
            
            <div className="mb-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Original Image</h4>
                <p className="text-sm text-gray-600">Size: {imageInfo.fileSize}</p>
                <p className="text-sm text-gray-600">Dimensions: {imageInfo.width} × {imageInfo.height}px</p>
            </div>

            <div className="mb-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Output Format</h4>
                <select
                    value={settings.outputFormat}
                    onChange={(e) => onSettingsChange({ outputFormat: e.target.value })}
                    disabled={isProcessing}
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                >
                    <option value="jpeg">JPEG</option>
                    <option value="png">PNG</option>
                    <option value="webp">WebP</option>
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Creativity Level: {settings.creativity}
                </label>
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.05"
                    value={settings.creativity}
                    onChange={(e) => onSettingsChange({ creativity: parseFloat(e.target.value) })}
                    disabled={isProcessing}
                    className="w-full"
                />
                <div className="flex justify-between text-xs text-gray-500">
                    <span>Conservative</span>
                    <span>Creative</span>
                </div>
            </div>

            <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Expected Result</h4>
                <p className="text-sm text-gray-600">
                    Output Dimensions: {imageInfo.width * 4} × {imageInfo.height * 4}px
                </p>
            </div>
        </div>
    </div>
);

export const ProgressBar = ({ progress }) => (
    <div className="fixed bottom-6 left-6 bg-white rounded-lg shadow-lg p-4 z-50">
        <div className="flex items-center">
            <div className="w-32 bg-gray-200 rounded-full h-2 mr-3">
                <div 
                    className="bg-purple-500 rounded-full h-2 transition-all duration-300"
                    style={{ width: `${progress}%` }}
                />
            </div>
            <span className="text-sm text-gray-600">
                {Math.round(progress)}%
            </span>
        </div>
    </div>
);
export const DropZone = ({ isDragging, onFileSelect, fileInputRef, validationError }) => (
    <div className="flex-grow flex justify-center items-start pt-4">
        <div
            ref={fileInputRef}
            className={`w-full max-w-3xl p-8 sm:p-12 flex flex-col items-center justify-center 
                     border-2 border-dashed rounded-lg transition-colors
                     ${isDragging ? 'border-purple-500 bg-purple-50' : 
                       validationError ? 'border-red-300' : 'border-gray-300'}`}
        >
            <div className="text-center">
                <Upload className={`mx-auto h-12 w-12 ${validationError ? 'text-red-400' : 'text-gray-400'}`} />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                    {validationError || "Drag & drop an image here"}
                </h3>
                {!validationError && (
                    <>
                        <p className="mt-1 text-sm text-gray-500">or</p>
                        <button 
                            onClick={onFileSelect}
                            className="mt-2 px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 
                                     focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 
                                     text-sm sm:text-base inline-flex items-center"
                        >
                            Browse Files
                        </button>
                    </>
                )}
                <p className="mt-2 text-xs text-gray-500">
                    Supported formats: JPEG, PNG, WebP (Max {formatFileSize(MAX_FILE_SIZE)})
                </p>
            </div>
        </div>
    </div>
);
export const ProcessingView = () => (
    <div className="flex flex-col items-center justify-center p-8">
        <Loader className="w-12 h-12 text-purple-500 animate-spin mb-4" />
        <p className="text-lg font-medium text-gray-700">
            Upscaling your image...
        </p>
        <p className="text-sm text-gray-500 mt-2">
            This may take a few moments
        </p>
    </div>
);

export const ImagePreview = ({ image, width, height }) => (
    <div className="relative">
        <img 
            src={image} 
            alt="Preview" 
            className="max-w-full h-auto rounded-lg shadow-lg"
        />
        <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded">
            {width} × {height}px
        </div>
    </div>
);