import React from 'react';
import { Helmet } from 'react-helmet';

export const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - ilikeimg</title>
        <meta name="description" content="Learn about how ilikeimg protects and handles your data" />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        <div className="prose prose-purple max-w-none">
          <p className="text-gray-600 mb-4">Last updated: November 26, 2024</p>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Information We Collect</h2>
            <div className="text-gray-600">
              <h3 className="font-medium mt-4">Account Information</h3>
              <p className="mb-4">
                - Email address
                - Username
                - Password (encrypted)
                - Google profile information (for OAuth users)
                - Subscription status and payment information
              </p>

              <h3 className="font-medium mt-4">Usage Data</h3>
              <p className="mb-4">
                - IP addresses
                - Browser type and version
                - Device information
                - Usage statistics and patterns
                - Credit usage and transactions
              </p>

              <h3 className="font-medium mt-4">Image Processing</h3>
              <p className="mb-4">
                We process your images but do not store them permanently. Images are automatically 
                deleted after processing or within 24 hours maximum.
              </p>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">How We Use Your Information</h2>
            <div className="text-gray-600">
              <ul className="list-disc pl-5">
                <li>Provide and improve our image processing services</li>
                <li>Manage your account and subscription</li>
                <li>Process payments and handle billing</li>
                <li>Send important notifications and updates</li>
                <li>Analyze and improve our services</li>
                <li>Prevent fraud and abuse</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Data Protection</h2>
            <p className="text-gray-600">
              We implement industry-standard security measures including:
              - SSL/TLS encryption for all data transmission
              - Secure cloud infrastructure using AWS
              - Regular security audits and updates
              - Automatic data deletion policies
              - Access controls and authentication
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Third-Party Services</h2>
            <p className="text-gray-600">
              We use trusted third-party services for specific functions:
              - Google (Authentication)
              - Stripe (Payment processing)
              - AWS (Cloud infrastructure)
              - Stability AI and Novita AI (Image processing)
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;