// src/utils/fileValidation.js
import { formatFileSize, SUPPORTED_FORMATS } from './conversionUtils';

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 in bytes

export class FileValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'FileValidationError';
  }
}

export const getSupportedMimeTypes = () => {
  // Get all unique MIME types from SUPPORTED_FORMATS
  return [...new Set(Object.values(SUPPORTED_FORMATS).map(format => format.mime))];
};

const getFileExtension = (filename) => {
  if (!filename) return null;
  const parts = filename.split('.');
  return parts.length > 1 ? `.${parts.pop().toLowerCase()}` : null;
};

export const validateFile = async (file, targetFormat = null) => {
  if (!file || typeof file !== 'object') {
    throw new FileValidationError('Invalid file object');
  }

  const errors = [];

  // Check file size
  if (file.size > MAX_FILE_SIZE) {
    errors.push(`File size (${formatFileSize(file.size)}) exceeds maximum limit of ${formatFileSize(MAX_FILE_SIZE)}`);
  }

  // Check supported file type
  const extension = getFileExtension(file.name);
  const mimeType = file.type;
  
  // Check if either the file extension or MIME type is supported
  const isExtensionSupported = extension && SUPPORTED_FORMATS[extension];
  const isMimeTypeSupported = Object.values(SUPPORTED_FORMATS).some(format => format.mime === mimeType);
  
  if (!isExtensionSupported && !isMimeTypeSupported) {
    const supportedExtList = Object.keys(SUPPORTED_FORMATS)
      .map(ext => ext.replace('.', '').toUpperCase())
      .join(', ');
    errors.push(`Unsupported file type. Supported formats: ${supportedExtList}`);
  }

  // Check if input format matches target format
  if (targetFormat && extension) {
    const normalizedExtension = extension.toLowerCase().replace('.', '');
    const normalizedTarget = targetFormat.toLowerCase();
    if (normalizedExtension === normalizedTarget) {
      errors.push(`File is already in ${targetFormat.toUpperCase()} format`);
    }
  }

  if (errors.length > 0) {
    throw new FileValidationError(errors.join('\n'));
  }

  return true;
};

export const validateFiles = async (files, targetFormat = null) => {
  if (!Array.isArray(files)) {
    throw new FileValidationError('Invalid files array');
  }

  const errors = [];

  for (const file of files) {
    try {
      await validateFile(file, targetFormat);
    } catch (error) {
      if (error instanceof FileValidationError) {
        errors.push(`${file.name}: ${error.message}`);
      } else {
        errors.push(`${file.name}: Unexpected error during validation`);
      }
    }
  }

  if (errors.length > 0) {
    throw new FileValidationError(errors.join('\n'));
  }

  return true;
};