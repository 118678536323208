// src/hooks/useFileConversion.js
import { useState, useCallback } from 'react';
import { 
  trackConversionStart, 
  trackConversionComplete, 
  trackConversionError,
  trackFileUpload 
} from '../utils/analytics';
import { getCustomFileName } from '../utils/conversionUtils';
import { logInDev } from '../utils/environment';

const API_URL = process.env.REACT_APP_API_URL;

export const useFileConversion = (outputFormat) => {
  const [conversionState, setConversionState] = useState({
    phase: null,
    currentFile: 0,
    totalFiles: 0,
    error: null,
    convertedFiles: null,
    isProcessing: false
  });

  const convertFiles = useCallback(async (inputFiles) => {
    if (!inputFiles?.length) {
      logInDev('❌ No files provided for conversion');
      return;
    }

    const startTime = performance.now();
    
    try {
      setConversionState({
        phase: 'converting',
        currentFile: 0,
        totalFiles: inputFiles.length,
        error: null,
        convertedFiles: null,
        isProcessing: true
      });
  
      trackConversionStart(inputFiles.length);
  
      const convertFormData = new FormData();
      
      // Add all files directly to FormData
      inputFiles.forEach(file => {
        if (file) {
          logInDev(`📄 Adding file: ${file.name}`);
          convertFormData.append('files', file);
        }
      });
  
      // Add conversion parameters
      convertFormData.append('quality', '85');
      convertFormData.append('page', '1');
  
      logInDev('🔄 Starting conversion process...');
      const response = await fetch(`${API_URL}/convert/${outputFormat}`, {
        method: 'POST',
        body: convertFormData,
      });
  
      if (!response.ok) {
        throw new Error('Conversion failed. Please try again.');
      }
  
      const blob = await response.blob();
      
      if (!blob || blob.size === 0) {
        throw new Error('Received empty response. Please try again.');
      }
  
      const isZip = response.headers.get('content-type')?.includes('zip');
      logInDev('📦 Response type:', response.headers.get('content-type'));
      
      const convertedFiles = [{
        name: isZip 
          ? `ilikeimg_batch_${new Date().toISOString().replace(/[-:]/g, '')}.zip`
          : getCustomFileName('converted', outputFormat),
        blob
      }];
  
      const endTime = performance.now();
      const conversionTime = endTime - startTime;

      trackConversionComplete(
        conversionTime,
        inputFiles.length,
        outputFormat,
        true,
        null,
        blob.size
      );
      logInDev('✅ Conversion completed successfully');
  
      setConversionState({
        phase: null,
        currentFile: 0,
        totalFiles: 0,
        error: null,
        convertedFiles,
        isProcessing: false
      });
  
      return convertedFiles;
  
    } catch (error) {
      logInDev('❌ Conversion error:', error);
      trackConversionError(error);

      setConversionState({
        phase: null,
        currentFile: 0,
        totalFiles: 0,
        error: 'An error occurred during conversion. Please try again.',
        convertedFiles: null,
        isProcessing: false
      });
    }
  }, [outputFormat]);

  const reset = useCallback(() => {
    setConversionState({
      phase: null,
      currentFile: 0,
      totalFiles: 0,
      error: null,
      convertedFiles: null,
      isProcessing: false
    });
  }, []);

  return {
    ...conversionState,
    convertFiles,
    reset
  };
};