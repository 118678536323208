import React from 'react';
import { getPasswordStrength } from '../../utils/passwordUtils';

const PasswordStrengthIndicator = ({ password }) => {
  const strength = getPasswordStrength(password);
  
  return (
    <div className="mt-2">
      <div className="flex justify-between mb-1">
        <span className="text-xs text-gray-600">Password strength:</span>
        <span className={`text-xs font-medium text-${strength.color}-600`}>
          {strength.label}
        </span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-1.5">
        <div
          className={`bg-${strength.color}-500 h-1.5 rounded-full transition-all duration-300`}
          style={{ width: `${strength.score * 100}%` }}
        />
      </div>
    </div>
  );
};

export default PasswordStrengthIndicator;