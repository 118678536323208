import React from 'react';
import { Helmet } from 'react-helmet';

export const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service - ilikeimg</title>
        <meta name="description" content="Review ilikeimg's terms of service and usage conditions" />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
        <div className="prose prose-purple max-w-none">
          <p className="text-gray-600 mb-4">Last updated: November 26, 2024</p>
          
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Acceptance of Terms</h2>
            <p className="text-gray-600">
              By accessing or using ilikeimg, you agree to be bound by these Terms of Service 
              and all applicable laws and regulations. If you disagree with any part of these 
              terms, you may not access our service.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Service Description</h2>
            <div className="text-gray-600">
              <p className="mb-4">
                ilikeimg provides image processing services including but not limited to:
                - Format conversion
                - Image compression
                - AI-powered upscaling
                - Face merging
                - Text removal
                - Image editing tools
              </p>
              <p>
                Services are provided on a freemium basis with both free and premium features.
              </p>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">3. User Accounts</h2>
            <div className="text-gray-600">
              <p className="mb-4">Users are responsible for:</p>
              <ul className="list-disc pl-5">
                <li>Maintaining account security</li>
                <li>Providing accurate information</li>
                <li>Reporting unauthorized access</li>
                <li>Managing subscription status</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Content Guidelines</h2>
            <p className="text-gray-600">
              Users must not process or upload:
              - Illegal content
              - Malicious software
              - Content that violates privacy rights
              - Content that infringes intellectual property rights
              - Explicit or inappropriate content
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Subscription Terms</h2>
            <div className="text-gray-600">
              <p className="mb-4">Premium subscriptions:</p>
              <ul className="list-disc pl-5">
                <li>Are billed according to selected plan</li>
                <li>Can be cancelled at any time</li>
                <li>Continue until end of billing period</li>
                <li>May have varying features and limits</li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Credits System</h2>
            <p className="text-gray-600">
              - Credits reset daily based on plan
              - Unused credits do not roll over
              - Credits cannot be transferred or sold
              - Credit allocation may change with notice
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Limitation of Liability</h2>
            <p className="text-gray-600">
              ilikeimg provides services "as is" without warranties. We are not liable for:
              - Service interruptions
              - Data loss
              - Processing results
              - Third-party service issues
              - Consequential damages
            </p>
          </section>
        </div>
      </div>
    </>
  );
};


export default TermsOfService;