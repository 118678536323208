import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';
import {
    ChevronRight,
    Settings,
    Loader,
    Lock,
} from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import { useCredits } from '../contexts/CreditContext';
import { pricingService } from '../services/PricingService';
import DownloadFile from './DownloadFile';
import { trackEvent } from '../utils/analytics';
import BeforeAfterSection from './BeforeAfterSection';
import ReviewCarousel from './ReviewCarousel';
import UploadSection from './UploadSection';
import FAQSection from './FAQSection';
import SampleExamplesGrid from './SampleExamplesGrid';
import CreditAlert from './CreditAlert';

import {
    validateImage,
    formatFileSize,
    upscaleImage,
    MAX_FILE_SIZE
} from '../utils/upscaleUtils';
import {
    ErrorMessage,
    SettingsDrawer,
    ProgressBar,
    ProcessingView,
    ImagePreview
} from './UpscaleComponents';


const UpscaleCredit = ({ image, showDownload, user, credits, operationCost }) => {
    if (!image || showDownload || !user || !operationCost || credits >= operationCost) {
        return null;
    }

    return (
        <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-md px-4">
            <CreditAlert
                currentCredits={credits}
                required={operationCost}
                isPremium={user.isPremium}
            />
        </div>
    );
};

const UpscaleImage = () => {
    const { user } = useContext(AuthContext);
    const { credits, refreshCredits } = useCredits();
    useEffect(() => {
        console.log('Current credits:', credits);
    }, [credits]);
    const navigate = useNavigate();
    const [operationCost, setOperationCost] = useState(null);

    const [state, setState] = useState({
        image: null,
        drawerOpen: false,
        imageInfo: { width: 0, height: 0, fileSize: '' },
        isProcessing: false,
        error: null,
        showDownload: false,
        downloadFile: null,
        uploadProgress: 0,
        upscaleSettings: {
            outputFormat: 'jpeg',
            creativity: 0.35
        }
    });

    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const dropZoneRef = useRef(null);


    useEffect(() => {
        const fetchPricing = async () => {
            try {
                const pricing = await pricingService.getPricing();
                setOperationCost(pricing?.credits?.costs?.UPSCALE ?? 10);
            } catch (error) {
                console.error('Failed to fetch pricing:', error);
                setOperationCost(10); // Fallback to default cost
            }
        };

        fetchPricing();
    }, []);

    const canUpscale = () => {
        if (!user) return false;  // Early return if not logged in
        if (state.isProcessing) return false;
        return operationCost ? credits >= operationCost : true;
    };
    
    const handleFile = async (file) => {
        try {
            setState(prev => ({ 
                ...prev, 
                error: null, 
                uploadProgress: 0,
                validationError: null 
            }));
    
            // Wait for validation before proceeding
            const validation = await validateImage(file);
    
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                
                reader.onload = (e) => {
                    setState(prev => ({
                        ...prev,
                        image: e.target.result,
                        imageInfo: {
                            width: validation.dimensions.width,
                            height: validation.dimensions.height,
                            fileSize: formatFileSize(file.size),
                        },
                        drawerOpen: window.innerWidth >= 640,
                        showDownload: false,
                        downloadFile: null
                    }));
    
                    // Track successful upload after state is updated
                    trackEvent('upscale_upload', {
                        file_size: file.size,
                        file_type: file.type,
                        dimensions: `${validation.dimensions.width}x${validation.dimensions.height}`,
                        user_id: user?.email || 'anonymous'
                    });
    
                    resolve();
                };
    
                reader.onerror = (error) => {
                    setState(prev => ({ 
                        ...prev, 
                        validationError: 'Error reading file' 
                    }));
                    reject(error);
                };
    
                reader.onprogress = (event) => {
                    if (event.lengthComputable) {
                        setState(prev => ({
                            ...prev,
                            uploadProgress: (event.loaded / event.total) * 100
                        }));
                    }
                };
    
                // Start reading the file
                reader.readAsDataURL(file);
            });
    
        } catch (error) {
            setState(prev => ({ 
                ...prev, 
                validationError: error.error || error.message 
            }));
            
            trackEvent('upscale_error', {
                error_type: 'validation',
                error_message: error.error || error.message,
                user_id: user?.email || 'anonymous'
            });
        }
    };

    const handleUpscale = async () => {
        if (!user) {
            navigate('/auth', {
                state: {
                    returnUrl: '/image-upscale',
                    message: 'Please sign in to use the AI upscale feature'
                }
            });
            return;
        }

        if (operationCost && credits < operationCost) {
            setState(prev => ({
                ...prev,
                error: `Insufficient credits. You need ${operationCost} credits for this operation.`
            }));
            return;
        }

        const timing = {
            start: Date.now(),
            uploadEnd: 0,
            processEnd: 0,
            downloadEnd: 0
        };

        try {
            setState(prev => ({ ...prev, isProcessing: true, error: null }));

            const file = await fetch(state.image).then(r => r.blob());
            const token = localStorage.getItem('accessToken');

            if (!token) {
                throw new Error('Authentication required');
            }

            const response = await upscaleImage(file, token, state.upscaleSettings);
            timing.uploadEnd = Date.now();

            if (response.status === 401) {
                navigate('/auth', {
                    state: {
                        returnUrl: '/image-upscale',
                        message: 'Your session has expired. Please sign in again.'
                    }
                });
                return;
            }

            if (response.status === 402) {
                setState(prev => ({
                    ...prev,
                    isProcessing: false,
                    error: "Insufficient credits for this operation."
                }));
                await refreshCredits(); // Refresh credits to show current balance
                return;
            }

            if (!response.ok) {
                throw new Error('Upscaling failed');
            }

            const blob = await response.blob();
            timing.downloadEnd = Date.now();

            const fileName = response.headers.get('content-disposition')?.split('filename=')[1]?.replace(/"/g, '') ||
                `ilikeimg_upscaled_${new Date().toISOString()}.${state.upscaleSettings.outputFormat}`;

            await refreshCredits(); // Refresh credits after successful operation

            trackEvent('upscale_complete', {
                original_size: state.imageInfo.fileSize,
                output_size: formatFileSize(blob.size),
                processing_time_ms: timing.downloadEnd - timing.uploadEnd,
                total_time_ms: timing.downloadEnd - timing.start,
                user_id: user.email
            });

            setState(prev => ({
                ...prev,
                showDownload: true,
                downloadFile: {
                    name: fileName,
                    blob
                },
                drawerOpen: false,
                isProcessing: false
            }));

        } catch (error) {
            timing.processEnd = Date.now();
            trackEvent('upscale_error', {
                error_type: 'processing',
                error_message: error.message,
                phase: timing.uploadEnd ? 'processing' : 'upload',
                timing: timing,
                user_id: user?.email
            });

            setState(prev => ({
                ...prev,
                error: error.message,
                isProcessing: false
            }));
        }
    };

    const handleSettingsChange = (settings) => {
        setState(prev => ({
            ...prev,
            upscaleSettings: {
                ...prev.upscaleSettings,
                ...settings
            }
        }));
    };

    useEffect(() => {
        const dropZone = dropZoneRef.current;
        if (!dropZone) return;

        const handlers = {
            dragenter: (e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDragging(true);
            },
            dragleave: (e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDragging(false);
            },
            dragover: (e) => {
                e.preventDefault();
                e.stopPropagation();
            },
            drop: (e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDragging(false);
                const files = e.dataTransfer.files;
                if (files?.length) handleFile(files[0]);
            }
        };

        Object.entries(handlers).forEach(([event, handler]) => {
            dropZone.addEventListener(event, handler);
        });

        return () => {
            Object.entries(handlers).forEach(([event, handler]) => {
                dropZone.removeEventListener(event, handler);
            });
        };
    }, []);
    const getButtonContent = () => {
        if (state.isProcessing) {
            return (
                <span className="flex items-center">
                    <Loader className="w-5 h-5 mr-2 animate-spin" />
                    Upscaling...
                </span>
            );
        }

        if (!user) {
            return (
                <>
                    <Lock className="w-5 h-5 mr-2" />
                    Sign in to Upscale
                </>
            );
        }

        if (operationCost && credits < operationCost) {
            return (
                <>
                    <Lock className="w-5 h-5 mr-2" />
                    Insufficient Credits
                </>
            );
        }

        return (
            <>
                Upscale 4x
                <ChevronRight className="w-5 h-5 ml-2" />
            </>
        );
    };
    return (
        <>
            <Helmet>
                <title>AI Image Upscaling - Free Online Tool | ilikeimg</title>
                <meta
                    name="description"
                    content="Upscale your images with AI-powered super-resolution. Increase image size by 4x while maintaining quality."
                />
            </Helmet>

            <div className="min-h-screen flex flex-col">
                <AnimatePresence>
                    {state.error && (
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            className="mx-4 mb-4"
                        >
                            <ErrorMessage message={state.error} />
                        </motion.div>
                    )}
                </AnimatePresence>

                <div className="flex flex-grow relative">
                    <div className="flex-grow overflow-y-auto">
                        <AnimatePresence mode="wait">
                            {state.showDownload ? (
                                <motion.div
                                    key="download"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <DownloadFile
                                        files={[state.downloadFile]}
                                        onBack={() => setState({
                                            image: null,
                                            drawerOpen: false,
                                            imageInfo: { width: 0, height: 0, fileSize: '' },
                                            isProcessing: false,
                                            error: null,
                                            showDownload: false,
                                            downloadFile: null,
                                            uploadProgress: 0
                                        })}
                                        toolName="AI Upscale"
                                        toolIdentifier="image-upscale"
                                    />
                                </motion.div>
                            ) : !state.image ? (
                                <motion.div
                                    key="landing"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <UploadSection
                                        isDragging={isDragging}
                                        onFileSelect={() => fileInputRef.current?.click()}
                                        dropZoneRef={dropZoneRef}
                                        user={user}
                                        formatFileSize={formatFileSize}
                                        MAX_FILE_SIZE={MAX_FILE_SIZE}
                                        validationError={state.validationError}  // Add this prop
                                    />
                                    <BeforeAfterSection />
                                    <SampleExamplesGrid />
                                    <div className="py-16 bg-gray-50">
                                        <div className="max-w-3xl mx-auto px-4">
                                            <h2 className="text-3xl font-bold text-center mb-12">
                                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
                                                    Frequently Asked Questions
                                                </span>
                                            </h2>
                                            <div className="bg-white rounded-2xl shadow-sm">
                                                <FAQSection />
                                            </div>
                                        </div>
                                    </div>
                                    <ReviewCarousel />
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="processing"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="flex-grow flex justify-center items-center p-4 sm:p-6"
                                >
                                    <div className="max-w-2xl w-full">
                                        {state.isProcessing ? (
                                            <ProcessingView />
                                        ) : (
                                            <ImagePreview
                                                image={state.image}
                                                width={state.imageInfo.width}
                                                height={state.imageInfo.height}
                                            />
                                        )}
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={(e) => e.target.files?.[0] && handleFile(e.target.files[0])}
                            accept="image/jpeg,image/png,image/webp"
                            className="hidden"
                        />
                    </div>

                    {state.image && !state.showDownload && (
                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 20 }}
                            className="sm:w-64 flex-shrink-0"
                        >
                            <SettingsDrawer
                                isOpen={state.drawerOpen}
                                imageInfo={state.imageInfo}
                                settings={state.upscaleSettings}
                                onSettingsChange={handleSettingsChange}
                                isProcessing={state.isProcessing}
                            />
                        </motion.div>
                    )}
                </div>

                {state.image && !state.showDownload && (
                    <>
                        <motion.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={() => setState(prev => ({
                                ...prev,
                                drawerOpen: !prev.drawerOpen
                            }))}
                            className="fixed right-0 top-1/2 transform -translate-y-1/2 bg-purple-500 text-white p-2 
                                     rounded-l-md shadow-md sm:hidden z-40 transition-colors hover:bg-purple-600"
                            aria-label="Toggle settings"
                        >
                            <Settings className="w-6 h-6" />
                        </motion.button>
                        <UpscaleCredit
                            image={state.image}
                            showDownload={state.showDownload}
                            user={user}
                            credits={credits}
                            operationCost={operationCost}
                        />

                        <motion.button
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.9 }}
                            onClick={handleUpscale}
                            disabled={!canUpscale()}
                            className={`fixed bottom-6 right-6 px-6 py-3 rounded-full shadow-lg 
                inline-flex items-center justify-center transition-all duration-300 z-50
                ${!user
                                    ? 'bg-purple-500 hover:bg-purple-600 hover:scale-105'
                                    : !canUpscale()
                                        ? 'bg-gray-400 cursor-not-allowed opacity-75'
                                        : 'bg-purple-500 hover:bg-purple-600 hover:scale-105'
                                } text-white`}
                            whileHover={{ scale: !user || canUpscale() ? 1.05 : 1 }}
                            whileTap={{ scale: !user || canUpscale() ? 0.95 : 1 }}
                        >
                            {getButtonContent()}
                        </motion.button>
                    </>
                )}

                {state.uploadProgress > 0 && state.uploadProgress < 100 && (
                    <ProgressBar progress={state.uploadProgress} />
                )}
            </div>
        </>
    );
};

export default UpscaleImage;