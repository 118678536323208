import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { 
  Upload, 
  ChevronRight, 
  Settings,
  Wand2,
  ArrowRight,
  FileQuestion,
  ImageIcon,
  SlidersHorizontal,
  Crop,
  LayoutGrid
} from 'lucide-react';
import DownloadFile from './DownloadFile';
import CropBox from './CropBox';

// Feature Card Component for Landing Page
const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="flex items-start p-4 bg-white rounded-xl border border-gray-100">
    <div className="flex-shrink-0">
      <div className="p-2 bg-purple-50 rounded-lg">
        <Icon className="w-5 h-5 text-purple-500" />
      </div>
    </div>
    <div className="ml-4">
      <h3 className="text-sm font-medium text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
    </div>
  </div>
);

// Related Tool Card Component
const RelatedTool = ({ icon: Icon, title, description, to }) => (
  <Link 
    to={to}
    className="flex items-start p-4 bg-white rounded-xl border border-gray-100 hover:border-purple-100 hover:shadow-sm transition-all"
  >
    <div className="flex-shrink-0">
      <div className="p-2 bg-purple-50 rounded-lg">
        <Icon className="w-5 h-5 text-purple-500" />
      </div>
    </div>
    <div className="ml-4">
      <h3 className="text-sm font-medium text-gray-900 flex items-center">
        {title}
        <ArrowRight className="w-4 h-4 ml-2 text-gray-400" />
      </h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
    </div>
  </Link>
);

// FAQ Item Component
const FAQItem = ({ question, answer }) => (
  <details className="group bg-white border border-gray-100 rounded-lg">
    <summary className="flex justify-between items-center p-4 cursor-pointer">
      <span className="text-sm font-medium text-gray-900">{question}</span>
      <FileQuestion className="w-5 h-5 text-gray-400 group-open:rotate-180 transition-transform" />
    </summary>
    <div className="px-4 pb-4 text-sm text-gray-600">
      {answer}
    </div>
  </details>
);

// Settings Drawer Component
const SettingsDrawer = ({ 
  isOpen, 
  onClose, 
  cropArea, 
  imageSize, 
  aspect, 
  isFreeAspect,
  onParameterChange, 
  onAspectRatioChange, 
  fileSize, 
  croppedSize 
}) => (
  <div className={`fixed top-0 right-0 w-64 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-30 h-full 
    ${isOpen ? 'translate-x-0' : 'translate-x-full'} sm:translate-x-0 sm:relative sm:transform-none sm:z-0`}
  >
    <div className="p-4 h-full flex flex-col overflow-y-auto">
      <div className="mb-4">
        <h3 className="text-lg font-medium">Crop Settings</h3>
      </div>

      {/* File Information */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          File Information
        </label>
        <div className="space-y-1">
          <p className="text-sm text-gray-600">Original size: {fileSize}</p>
          <p className="text-sm text-gray-600">Estimated size: {croppedSize}</p>
          <p className="text-sm text-gray-600">
            Dimensions: {Math.round(imageSize.width)}x{Math.round(imageSize.height)}px
          </p>
        </div>
      </div>

      {/* Aspect Ratio Selection */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Aspect Ratio
        </label>
        <div className="grid grid-cols-2 gap-2">
          {['1:1', '4:3', '16:9', 'Free'].map((ratio) => (
            <button
              key={ratio}
              className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors
                ${aspect === ratio || (ratio === 'Free' && isFreeAspect)
                  ? 'bg-purple-500 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
              onClick={() => onAspectRatioChange(ratio)}
            >
              {ratio}
            </button>
          ))}
        </div>
      </div>

      {/* Dimensions Input */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Dimensions
        </label>
        <div className="grid grid-cols-2 gap-3">
          <div>
            <label className="block text-xs text-gray-500 mb-1">Width (px)</label>
            <input
              type="number"
              value={Math.round(cropArea.width)}
              onChange={(e) => onParameterChange('width', e.target.value)}
              min={20}
              max={imageSize.width}
              className="w-full px-3 py-1.5 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-xs text-gray-500 mb-1">Height (px)</label>
            <input
              type="number"
              value={Math.round(cropArea.height)}
              onChange={(e) => onParameterChange('height', e.target.value)}
              min={20}
              max={imageSize.height}
              className="w-full px-3 py-1.5 border rounded-lg"
            />
          </div>
        </div>
      </div>

      {/* Position Input */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Position
        </label>
        <div className="grid grid-cols-2 gap-3">
          <div>
            <label className="block text-xs text-gray-500 mb-1">X (px)</label>
            <input
              type="number"
              value={Math.round(cropArea.x)}
              onChange={(e) => onParameterChange('x', e.target.value)}
              min={0}
              max={imageSize.width - cropArea.width}
              className="w-full px-3 py-1.5 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-xs text-gray-500 mb-1">Y (px)</label>
            <input
              type="number"
              value={Math.round(cropArea.y)}
              onChange={(e) => onParameterChange('y', e.target.value)}
              min={0}
              max={imageSize.height - cropArea.height}
              className="w-full px-3 py-1.5 border rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
const CropImage = () => {
    // State Management
    const [state, setState] = useState({
      image: null,
      imageSize: { width: 0, height: 0 },
      displaySize: { width: 0, height: 0 },
      cropArea: { x: 0, y: 0, width: 0, height: 0 },
      aspect: '1:1',
      drawerOpen: false,
      croppedImage: null,
      isFreeAspect: false,
      scaleFactor: 1,
      fileSize: '',
      croppedSize: ''
    });
    
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const imageRef = useRef(null);
    const containerRef = useRef(null);
    const dropZoneRef = useRef(null);
    const handleParameterChange = (parameter, value) => {
        const numValue = parseInt(value, 10);
        if (isNaN(numValue)) return;
      
        setState(prevState => {
          let newCropArea = { ...prevState.cropArea };
          const aspectRatio = prevState.isFreeAspect ? null : eval(prevState.aspect.replace(':', '/'));
      
          switch (parameter) {
            case 'x': {
              // Handle X position change
              newCropArea.x = Math.max(0, Math.min(numValue, prevState.imageSize.width - newCropArea.width));
              break;
            }
            
            case 'y': {
              // Handle Y position change
              newCropArea.y = Math.max(0, Math.min(numValue, prevState.imageSize.height - newCropArea.height));
              break;
            }
            
            case 'width': {
              // Store the center point
              const centerX = newCropArea.x + newCropArea.width / 2;
              const centerY = newCropArea.y + newCropArea.height / 2;
      
              // Update width
              newCropArea.width = Math.max(20, Math.min(numValue, prevState.imageSize.width));
      
              // If aspect ratio is locked, adjust height accordingly
              if (aspectRatio) {
                newCropArea.height = newCropArea.width / aspectRatio;
                
                // If new height exceeds image bounds, adjust both dimensions
                if (newCropArea.height > prevState.imageSize.height) {
                  newCropArea.height = prevState.imageSize.height;
                  newCropArea.width = newCropArea.height * aspectRatio;
                }
              }
      
              // Recenter the crop area
              newCropArea.x = centerX - newCropArea.width / 2;
              newCropArea.y = centerY - newCropArea.height / 2;
              break;
            }
            
            case 'height': {
              // Store the center point
              const centerX = newCropArea.x + newCropArea.width / 2;
              const centerY = newCropArea.y + newCropArea.height / 2;
      
              // Update height
              newCropArea.height = Math.max(20, Math.min(numValue, prevState.imageSize.height));
      
              // If aspect ratio is locked, adjust width accordingly
              if (aspectRatio) {
                newCropArea.width = newCropArea.height * aspectRatio;
                
                // If new width exceeds image bounds, adjust both dimensions
                if (newCropArea.width > prevState.imageSize.width) {
                  newCropArea.width = prevState.imageSize.width;
                  newCropArea.height = newCropArea.width / aspectRatio;
                }
              }
      
              // Recenter the crop area
              newCropArea.x = centerX - newCropArea.width / 2;
              newCropArea.y = centerY - newCropArea.height / 2;
              break;
            }
          }
      
          // Ensure crop area stays within image bounds
          newCropArea.x = Math.max(0, Math.min(newCropArea.x, prevState.imageSize.width - newCropArea.width));
          newCropArea.y = Math.max(0, Math.min(newCropArea.y, prevState.imageSize.height - newCropArea.height));
      
          // Update estimated cropped size
          updateCroppedSize(newCropArea, prevState.imageSize, prevState.fileSize);
      
          // Determine if we need to update aspect ratio state
          const newAspectRatio = determineAspectRatio(newCropArea.width, newCropArea.height);
          const newIsFreeAspect = newAspectRatio === 'Free';
      
          return {
            ...prevState,
            cropArea: newCropArea,
            aspect: newIsFreeAspect ? prevState.aspect : newAspectRatio,
            isFreeAspect: newIsFreeAspect
          };
        });
      };
    // Static Data
    const features = [
      {
        icon: Crop,
        title: "Precise Cropping",
        description: "Pixel-perfect cropping with interactive handles and grid overlay"
      },
      {
        icon: LayoutGrid,
        title: "Smart Guides",
        description: "Rule of thirds grid and dimension guides for perfect composition"
      },
      {
        icon: SlidersHorizontal,
        title: "Flexible Controls",
        description: "Multiple aspect ratios and precise dimension controls"
      }
    ];
  
    const relatedTools = [
      {
        icon: Wand2,
        title: "AI Image Upscaler",
        description: "Enhance resolution and quality with AI technology",
        to: "/image-upscale"
      },
      {
        icon: ImageIcon,
        title: "Image Compressor",
        description: "Optimize file size while preserving quality",
        to: "/image-compress"
      }
    ];
  
    const faqs = [
      {
        question: "What image formats are supported?",
        answer: "Our tool supports all common image formats including JPG, PNG, WebP, and GIF. The cropped image will be saved in high-quality JPG format."
      },
      {
        question: "How do I maintain the aspect ratio while cropping?",
        answer: "Use the aspect ratio presets (1:1, 4:3, 16:9) in the settings panel. The crop area will automatically maintain the selected ratio as you resize."
      },
      {
        question: "Can I see the dimensions while cropping?",
        answer: "Yes, the current dimensions are displayed above the crop area and can be precisely adjusted using the settings panel."
      },
      {
        question: "Is there a size limit for images?",
        answer: "You can upload images up to 10MB in size. For larger images, try our compression tool first to optimize the file size."
      }
    ];
  
    // Helper Functions
    const formatFileSize = (bytes) => {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };
  
    const determineAspectRatio = (width, height) => {
      const ratio = width / height;
      const tolerance = 0.1;
      
      const commonRatios = {
        '1:1': 1,
        '4:3': 4/3,
        '16:9': 16/9
      };
      
      let closestRatio = 'Free';
      let minDifference = Number.MAX_VALUE;
      
      Object.entries(commonRatios).forEach(([name, value]) => {
        const difference = Math.abs(ratio - value);
        if (difference < tolerance && difference < minDifference) {
          minDifference = difference;
          closestRatio = name;
        }
      });
      
      return closestRatio;
    };
  
    // Event Handlers
    const handleFile = (file) => {
      const formattedFileSize = formatFileSize(file.size);
      const reader = new FileReader();
      
      reader.onload = (e) => {
        setState(prevState => ({
          ...prevState,
          image: e.target.result,
          fileSize: formattedFileSize,
          drawerOpen: window.innerWidth >= 640
        }));
      };
      
      reader.readAsDataURL(file);
    };
  
    const handleFileChange = (event) => {
      if (event.target.files?.[0]) {
        handleFile(event.target.files[0]);
      }
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
  
      const file = e.dataTransfer.files?.[0];
      if (file) {
        handleFile(file);
      }
    };
  
    // Cropping Handlers
    const handleCropChange = (newCropArea) => {
      const aspectRatio = determineAspectRatio(newCropArea.width, newCropArea.height);
      const isFreeAspect = aspectRatio === 'Free';
      
      setState(prevState => ({
        ...prevState,
        cropArea: newCropArea,
        aspect: aspectRatio,
        isFreeAspect
      }));
    };
  
    const handleAspectRatioChange = (newAspect) => {
      setState(prevState => {
        const isFreeAspect = newAspect === 'Free';
        const aspect = isFreeAspect ? '1:1' : newAspect;
        const aspectRatio = isFreeAspect ? null : eval(aspect.replace(':', '/'));
  
        let newCropArea = { ...prevState.cropArea };
  
        if (aspectRatio) {
          // Maintain center point while adjusting aspect ratio
          const centerX = newCropArea.x + newCropArea.width / 2;
          const centerY = newCropArea.y + newCropArea.height / 2;
  
          if (newCropArea.width / newCropArea.height > aspectRatio) {
            newCropArea.width = newCropArea.height * aspectRatio;
          } else {
            newCropArea.height = newCropArea.width / aspectRatio;
          }
  
          // Recenter the crop area
          newCropArea.x = centerX - newCropArea.width / 2;
          newCropArea.y = centerY - newCropArea.height / 2;
  
          // Ensure within bounds
          if (newCropArea.x + newCropArea.width > prevState.imageSize.width) {
            newCropArea.x = prevState.imageSize.width - newCropArea.width;
          }
          if (newCropArea.y + newCropArea.height > prevState.imageSize.height) {
            newCropArea.y = prevState.imageSize.height - newCropArea.height;
          }
        }
  
        return {
          ...prevState,
          aspect,
          isFreeAspect,
          cropArea: newCropArea
        };
      });
    };

    // Continue from previous CropImage component...

  // Effects
  useEffect(() => {
    const dropZone = dropZoneRef.current;
    if (!dropZone) return;

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    dropZone.addEventListener('dragenter', handleDragEnter);
    dropZone.addEventListener('dragleave', handleDragLeave);
    dropZone.addEventListener('dragover', handleDragOver);
    dropZone.addEventListener('drop', handleDrop);

    return () => {
      dropZone.removeEventListener('dragenter', handleDragEnter);
      dropZone.removeEventListener('dragleave', handleDragLeave);
      dropZone.removeEventListener('dragover', handleDragOver);
      dropZone.removeEventListener('drop', handleDrop);
    };
  }, []);

  useEffect(() => {
    if (state.image && containerRef.current) {
      const img = new Image();
      img.onload = () => {
        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;
        const imageAspectRatio = img.width / img.height;
        const containerAspectRatio = containerWidth / containerHeight;

        let newWidth, newHeight, scaleFactor;

        if (imageAspectRatio > containerAspectRatio) {
          newWidth = containerWidth;
          newHeight = containerWidth / imageAspectRatio;
          scaleFactor = containerWidth / img.width;
        } else {
          newHeight = containerHeight;
          newWidth = containerHeight * imageAspectRatio;
          scaleFactor = containerHeight / img.height;
        }

        const cropWidth = newWidth / 2;
        const cropHeight = newHeight / 2;
        const cropX = (img.width - cropWidth / scaleFactor) / 2;
        const cropY = (img.height - cropHeight / scaleFactor) / 2;

        const newCropArea = {
          x: cropX,
          y: cropY,
          width: cropWidth / scaleFactor,
          height: cropHeight / scaleFactor
        };

        setState(prevState => ({
          ...prevState,
          imageSize: { width: img.width, height: img.height },
          displaySize: { width: newWidth, height: newHeight },
          cropArea: newCropArea,
          scaleFactor: scaleFactor
        }));

        updateCroppedSize(
          newCropArea, 
          { width: img.width, height: img.height }, 
          state.fileSize
        );
      };
      img.src = state.image;
    }
  }, [state.image]);

  useEffect(() => {
    const handleResize = () => {
      if (state.image) {
        setState(prevState => ({
          ...prevState,
          drawerOpen: window.innerWidth >= 640
        }));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [state.image]);

  // Image Processing Functions
  const updateCroppedSize = (cropArea, imageSize, fileSize) => {
    if (!fileSize) return;
    
    const croppedPixels = cropArea.width * cropArea.height;
    const totalPixels = imageSize.width * imageSize.height;
    const croppedRatio = croppedPixels / totalPixels;
    
    const [size, unit] = fileSize.split(' ');
    const fileSizeInBytes = size * Math.pow(1024, ['Bytes', 'KB', 'MB', 'GB'].indexOf(unit));
    const estimatedSize = croppedRatio * fileSizeInBytes;
    
    setState(prevState => ({
      ...prevState,
      croppedSize: formatFileSize(estimatedSize)
    }));
  };

  const getCroppedImg = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = state.cropArea.width;
    canvas.height = state.cropArea.height;
    const ctx = canvas.getContext('2d');
  
    const img = new Image();
    await new Promise((resolve) => {
      img.onload = resolve;
      img.src = state.image;
    });
  
    ctx.drawImage(
      img,
      state.cropArea.x,
      state.cropArea.y,
      state.cropArea.width,
      state.cropArea.height,
      0,
      0,
      state.cropArea.width,
      state.cropArea.height
    );
  
    // Try to determine original format from data URL or file extension
    let outputFormat = 'image/jpeg';
    let quality = 0.95;
  
    if (state.image.startsWith('data:image/')) {
      const mimeType = state.image.split(';')[0].split(':')[1];
      if (['image/jpeg', 'image/png', 'image/webp'].includes(mimeType)) {
        outputFormat = mimeType;
      }
    }
  
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const extension = outputFormat.split('/')[1].replace('jpeg', 'jpg');
        resolve({
          blob,
          extension
        });
      }, outputFormat, quality);
    });
  };

  const handleCrop = async () => {
    try {
      const { blob, extension } = await getCroppedImg();
      const timestamp = new Date().getTime();
      const fileName = `cropped_${timestamp}.${extension}`;
      setState(prevState => ({
        ...prevState,
        croppedImage: [{ name: fileName, blob: blob }],
        drawerOpen: false
      }));
    } catch (e) {
      console.error('Error during crop:', e);
    }
  };

  const resetCrop = () => {
    setState({
      image: null,
      imageSize: { width: 0, height: 0 },
      displaySize: { width: 0, height: 0 },
      cropArea: { x: 0, y: 0, width: 0, height: 0 },
      aspect: '1:1',
      drawerOpen: false,
      croppedImage: null,
      isFreeAspect: false,
      scaleFactor: 1,
      fileSize: '',
      croppedSize: '',
    });
  };

  // Render
  return (
    <>
      <Helmet>
        <title>Free Online Image Cropper - Precise Crop Tool | ilikeimg</title>
        <meta name="description" content="Professional online image cropping tool with precise controls, aspect ratios, and AI enhancement options. Free to use, no registration required." />
      </Helmet>
      
      <div className="min-h-screen flex flex-col bg-gray-50">
        {!state.image ? (
          <div className="max-w-6xl mx-auto px-4 py-8 w-full">
            {/* Landing Page Content */}
            <div className="text-center mb-8">
              <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
                Professional Image Cropping Tool
              </h1>
              <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                Crop images with precision using our advanced tools. Perfect for social media, 
                profile pictures, and professional use.
              </p>
            </div>

            {/* Features Grid */}
            <div className="grid md:grid-cols-3 gap-6 mb-12">
              {features.map((feature, index) => (
                <FeatureCard key={index} {...feature} />
              ))}
            </div>

            {/* Upload Area */}
            <div
              ref={dropZoneRef}
              className={`w-full p-12 flex flex-col items-center justify-center border-2 border-dashed 
                rounded-xl transition-colors mb-12 
                ${isDragging ? 'border-purple-500 bg-purple-50' : 'border-gray-300'}`}
            >
              <Upload className="h-12 w-12 text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                Drop your image here
              </h3>
              <p className="text-gray-500 mb-4">or</p>
              <button 
                onClick={() => fileInputRef.current?.click()}
                className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700
                  focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
              >
                Choose File
              </button>
              <p className="mt-4 text-sm text-gray-500">
                Supported formats: JPG, PNG, WebP, GIF
              </p>
            </div>

            {/* Related Tools */}
            <div className="mb-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Enhance Your Images
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                {relatedTools.map((tool, index) => (
                  <RelatedTool key={index} {...tool} />
                ))}
              </div>
            </div>

            {/* FAQs */}
            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Frequently Asked Questions
              </h2>
              <div className="space-y-4">
                {faqs.map((faq, index) => (
                  <FAQItem key={index} {...faq} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          // Cropping Interface
          <div className="flex flex-grow relative">
            <div className="flex-grow p-4 sm:p-6 overflow-y-auto">
              {state.croppedImage ? (
                <DownloadFile
                  files={state.croppedImage}
                  onBack={resetCrop}
                  toolName="Crop Image"
                  toolIdentifier="image-crop"
                />
              ) : (
                <div className="flex-grow flex justify-center items-center" ref={containerRef}>
                  <div className="relative">
                    <img
                      ref={imageRef}
                      src={state.image}
                      alt="Original"
                      className="max-w-full max-h-[calc(100vh-250px)] object-contain"
                    />
                    {state.displaySize.width > 0 && (
                      <CropBox
                        cropArea={state.cropArea}
                        imageSize={state.imageSize}
                        scaleFactor={state.scaleFactor}
                        onCropChange={handleCropChange}
                        aspect={state.aspect}
                        isFreeAspect={state.isFreeAspect}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Settings Panel */}
            {state.image && !state.croppedImage && (
              <div className="sm:w-64 flex-shrink-0">
                <SettingsDrawer
                  isOpen={state.drawerOpen}
                  onClose={() => setState(prev => ({ ...prev, drawerOpen: !prev.drawerOpen }))}
                  cropArea={state.cropArea}
                  imageSize={state.imageSize}
                  aspect={state.aspect}
                  isFreeAspect={state.isFreeAspect}
                  onParameterChange={handleParameterChange}
                  onAspectRatioChange={handleAspectRatioChange}
                  fileSize={state.fileSize}
                  croppedSize={state.croppedSize}
                />
              </div>
            )}

            {/* Mobile Settings Toggle & Crop Button */}
            {state.image && !state.croppedImage && (
              <>
                <button
                  onClick={() => setState(prev => ({ ...prev, drawerOpen: !prev.drawerOpen }))}
                  className="fixed right-0 top-1/2 -translate-y-1/2 bg-purple-500 text-white p-2 
                    rounded-l-lg shadow-lg sm:hidden z-40"
                >
                  <Settings className="w-6 h-6" />
                </button>
                <button
                  onClick={handleCrop}
                  className="fixed bottom-6 right-6 bg-purple-600 text-white px-6 py-3 
                    rounded-full shadow-lg inline-flex items-center hover:bg-purple-700 z-50"
                >
                  <span>Crop Image</span>
                  <ChevronRight className="w-5 h-5 ml-2" />
                </button>
              </>
            )}
          </div>
        )}

        {/* Hidden File Input */}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          className="hidden"
        />
      </div>
    </>
  );
};

export default CropImage;