import React, { useState, useEffect, useContext } from 'react';
import {
  Settings,
  Zap,
  CheckCircle,
  AlertCircle,
  Crown,
  Loader,
  X,
  Clock
} from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import { paymentService } from '../services/PaymentService';

const Toast = ({ type, message, onClose }) => (
  <div className="fixed top-4 right-4 z-50">
    <div className={`p-4 rounded-lg shadow-lg flex items-center space-x-2 ${type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
      }`}>
      <span>{message}</span>
      <button onClick={onClose} className="p-1">
        <X className="w-4 h-4" />
      </button>
    </div>
  </div>
);

const CancellationSurvey = ({ isOpen, onClose, onSubmit, loading }) => {
  const [reason, setReason] = useState('');
  const [feedback, setFeedback] = useState('');

  const reasons = [
    'Too expensive',
    'Not using enough',
    'Missing features',
    'Found alternative',
    'Technical issues',
    'Other'
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl p-6 max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">We're sorry to see you go</h3>
        <p className="text-gray-600 mb-4">
          Your feedback helps us improve. Why are you cancelling?
        </p>

        <div className="space-y-4 mb-4">
          {reasons.map((r) => (
            <label key={r} className="flex items-center">
              <input
                type="radio"
                name="reason"
                value={r}
                checked={reason === r}
                onChange={(e) => setReason(e.target.value)}
                className="text-purple-600"
              />
              <span className="ml-2 text-gray-700">{r}</span>
            </label>
          ))}
        </div>

        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Any additional feedback?"
          className="w-full p-2 border rounded-lg mb-4"
          rows={3}
        />

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            disabled={loading}
            className="px-4 py-2 rounded-lg border border-gray-200 hover:bg-gray-50"
          >
            Keep Subscription
          </button>
          <button
            onClick={() => onSubmit({ reason, feedback })}
            disabled={loading || !reason}
            className="px-4 py-2 rounded-lg bg-red-600 text-white hover:bg-red-700 
                     disabled:opacity-50 flex items-center"
          >
            {loading && <Loader className="w-4 h-4 mr-2 animate-spin" />}
            {loading ? 'Canceling...' : 'Confirm Cancellation'}
          </button>
        </div>
      </div>
    </div>
  );
};

const AccountSettings = () => {
  const { user, refreshUser } = useContext(AuthContext);
  const [credits, setCredits] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pricingInfo, setPricingInfo] = useState(null);
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [showCancellationSurvey, setShowCancellationSurvey] = useState(false);
  const [toast, setToast] = useState(null);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('accessToken');
      const [pricingData, creditsData] = await Promise.all([
        paymentService.getPricing(),
        paymentService.getUserCredits(token)
      ]);

      setCredits(creditsData.credits);
      setPricingInfo(pricingData);
      await refreshUser(true);
    } catch (err) {
      setError(err.message || 'Failed to load account information');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refreshUser]);

  const handleUpgrade = async () => {
    setUpgradeLoading(true);
    try {
      const token = localStorage.getItem('accessToken');
      const { url } = await paymentService.createCheckoutSession(token);
      window.location.href = url;
    } catch (err) {
      setToast({
        type: 'error',
        message: 'Failed to initiate upgrade'
      });
    } finally {
      setUpgradeLoading(false);
    }
  };

  const handleResume = async () => {
    setResumeLoading(true);
    try {
      const token = localStorage.getItem('accessToken');
      await paymentService.resumeSubscription(token);
      setToast({
        type: 'success',
        message: 'Subscription resumed successfully'
      });
      await refreshUser();
    } catch (error) {
      setToast({
        type: 'error',
        message: 'Failed to resume subscription'
      });
    } finally {
      setResumeLoading(false);
    }
  };

  const handleCancelSubmit = async (surveyData) => {
    setCancelLoading(true);
    try {
      const token = localStorage.getItem('accessToken');
      const response = await paymentService.cancelSubscription(token, surveyData);
      console.log('Cancel response:', response);
      
      setToast({
        type: 'success',
        message: 'Subscription will be cancelled at the end of the billing period'
      });
      
      // Force refresh user data
      await refreshUser(true);
      
      // Log user data after refresh
      console.log('User data after cancel:', user);
      
      setShowCancellationSurvey(false);
    } catch (error) {
      console.error('Cancel error:', error);
      setToast({
        type: 'error',
        message: 'Failed to cancel subscription'
      });
    } finally {
      setCancelLoading(false);
    }
  };
  
  // Add useEffect to log user data changes
  useEffect(() => {
    console.log('User data updated:', user);
  }, [user]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center">
          <Loader className="w-8 h-8 animate-spin text-purple-600" />
          <p className="mt-2 text-gray-600">Loading account information...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {toast && (
        <Toast
          type={toast.type}
          message={toast.message}
          onClose={() => setToast(null)}
        />
      )}

      <CancellationSurvey
        isOpen={showCancellationSurvey}
        onClose={() => setShowCancellationSurvey(false)}
        onSubmit={handleCancelSubmit}
        loading={cancelLoading}
      />

      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Account Settings</h1>
        <p className="text-gray-600">Manage your account and subscription</p>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-xl flex items-start">
          <AlertCircle className="w-5 h-5 text-red-500 mt-0.5 mr-2 flex-shrink-0" />
          <div>
            <p className="text-sm text-red-600">{error}</p>
            <button
              onClick={fetchData}
              className="mt-2 text-sm text-red-700 hover:text-red-800 font-medium"
            >
              Try Again
            </button>
          </div>
        </div>
      )}

      {user?.isPremium && (
        <div className={`mb-6 p-4 ${user.willCancelAt ? 'bg-yellow-50' : 'bg-green-50'} rounded-lg flex items-start`}>
          {user.willCancelAt ? (
            <>
              <Clock className="w-5 h-5 text-yellow-500 mt-0.5 mr-2 flex-shrink-0" />
              <div>
                <p className="text-sm text-yellow-700 font-medium">
                  Your Premium subscription will end on {formatDate(user.willCancelAt)}
                </p>
                <p className="text-sm text-yellow-600 mt-1">
                  You'll continue to enjoy all Premium benefits until then.
                </p>
                <button
                  onClick={handleResume}
                  disabled={resumeLoading}
                  className="mt-3 text-sm font-medium text-yellow-700 hover:text-yellow-800 
                     flex items-center disabled:opacity-50"
                >
                  {resumeLoading ? (
                    <>
                      <Loader className="w-4 h-4 mr-2 animate-spin" />
                      Resuming...
                    </>
                  ) : (
                    'Resume Subscription'
                  )}
                </button>
              </div>
            </>
          ) : (
            <>
              <Crown className="w-5 h-5 text-green-500 mt-0.5 mr-2 flex-shrink-0" />
              <div>
                <p className="text-sm text-green-700 font-medium">
                  Your Premium subscription is active
                </p>
              </div>
            </>
          )}
        </div>
      )}

      <div className="grid lg:grid-cols-3 gap-8">
        {/* Account Info */}
        <div className="lg:col-span-1">
          <div className="bg-white rounded-xl border border-gray-200 p-6">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <Settings className="w-5 h-5 mr-2" /> Account Information
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-500">Plan Status</label>
                <p className="mt-1 flex items-center">
                  {user?.isPremium ? (
                    <>
                      <Crown className="w-4 h-4 text-yellow-500 mr-1" />
                      {user.willCancelAt ? (
                        <span>
                          Premium (Cancels {formatDate(user.willCancelAt)})
                        </span>
                      ) : (
                        'Premium Member'
                      )}
                    </>
                  ) : (
                    'Free Member'
                  )}
                </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Email</label>
                <p className="mt-1">{user?.email}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Username</label>
                <p className="mt-1">{user?.username}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Member Since</label>
                <p className="mt-1">{new Date(user?.created_at).toLocaleDateString()}</p>
              </div>
            </div>
          </div>

          {/* Credits Info */}
          <div className="mt-6 bg-white rounded-xl border border-gray-200 p-6">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <Zap className="w-5 h-5 mr-2" /> Credits
            </h2>
            <div className="flex items-baseline space-x-2">
              <span className="text-3xl font-bold">{credits}</span>
              <span className="text-gray-600">credits remaining</span>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              Credits reset daily at midnight UTC
            </p>
          </div>
        </div>

        {/* Plans */}
        <div className="lg:col-span-2">
          <h2 className="text-lg font-semibold mb-6">Available Plans</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {/* Free Plan */}
            <div className={`bg-white p-6 rounded-xl border ${!user?.isPremium ? 'border-purple-500' : 'border-gray-200'}`}>
              <h3 className="text-xl font-semibold">Free Plan</h3>
              <p className="text-gray-600 mt-2">Free Forever</p>
              <div className="my-6">
                <div className="flex items-baseline">
                  <span className="text-3xl font-bold">20</span>
                  <span className="ml-2 text-gray-600">credits/day</span>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">Image conversion</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">
                  <span className="text-gray-600">Image cropping</span>
                  </span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">
                  <span className="text-gray-600">Image Compression</span>
                  </span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">
                  <span className="text-gray-600">Image Resizing</span>
                  </span>
                </div>
              </div>
            </div>

            {/* Premium Plan */}
            <div className={`bg-white p-6 rounded-xl border ${user?.isPremium ? 'border-purple-500' : 'border-gray-200'}`}>
              <h3 className="text-xl font-semibold flex items-center">
                <Crown className="w-5 h-5 text-yellow-500 mr-2" />
                Premium Plan
              </h3>
              <div className="mt-2 flex items-baseline">
                <span className="text-3xl font-bold">${pricingInfo?.premiumPrice}</span>
                <span className="text-gray-600 ml-1">/month</span>
              </div>
              <div className="my-6">
                <div className="flex items-baseline">
                  <span className="text-3xl font-bold">{pricingInfo?.premiumCreditsDaily}</span>
                  <span className="ml-2 text-gray-600">credits/day</span>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">Everything in Free plan</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">Image upscaling up to 20 images per day</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">Face swapping up to 20 images per day</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">Text/watermark removal up to 20 images per day</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">24/7 priority support</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-600">
                    Up to {(pricingInfo?.premiumSizeLimit / (1024 * 1024)).toFixed(0)}MB file size
                  </span>
                </div>
              </div>

              {/* Button Section */}
              {user?.isPremium ? (
                <div className="mt-6">
                  {user.willCancelAt ? (
                    <button
                      onClick={handleResume}
                      disabled={resumeLoading}
                      className="w-full py-2.5 px-4 bg-purple-600 text-white rounded-lg 
                   hover:bg-purple-700 transition-colors duration-150 flex items-center 
                   justify-center disabled:opacity-50"
                    >
                      {resumeLoading ? (
                        <>
                          <Loader className="w-5 h-5 animate-spin mr-2" />
                          Processing...
                        </>
                      ) : (
                        'Resume Subscription'
                      )}
                    </button>
                  ) : (
                    <button
                      onClick={() => setShowCancellationSurvey(true)}
                      className="w-full text-sm text-gray-500 hover:text-red-600 
                   transition-colors duration-150"
                    >
                      Cancel subscription
                    </button>
                  )}
                </div>
              ) : (
                <button
                  onClick={handleUpgrade}
                  disabled={upgradeLoading}
                  className="w-full mt-6 py-2.5 px-4 bg-purple-600 text-white rounded-lg 
               hover:bg-purple-700 transition-colors duration-150 flex items-center 
               justify-center disabled:opacity-50"
                >
                  {upgradeLoading ? (
                    <>
                      <Loader className="w-5 h-5 animate-spin mr-2" />
                      Processing...
                    </>
                  ) : (
                    'Upgrade to Premium'
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;