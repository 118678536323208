// upscaleUtils.js
export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const MAX_DIMENSION = 2048;
export const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/webp'];
export const DEFAULT_OUTPUT_FORMAT = 'jpeg';

export const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const validateImage = async (file) => {
    return new Promise((resolve, reject) => {
        // Create validation result object
        const result = {
            isValid: true,
            error: null,
            dimensions: null
        };

        if (!SUPPORTED_FORMATS.includes(file.type)) {
            result.isValid = false;
            result.error = 'Please upload a JPEG, PNG, or WebP image';
            reject(result);
            return;
        }

        if (file.size > MAX_FILE_SIZE) {
            result.isValid = false;
            result.error = `Image must be smaller than ${formatFileSize(MAX_FILE_SIZE)}`;
            reject(result);
            return;
        }

        const img = new Image();
        img.onload = () => {
            URL.revokeObjectURL(img.src);
            if (img.width > MAX_DIMENSION || img.height > MAX_DIMENSION) {
                result.isValid = false;
                result.error = `Image dimensions must be ${MAX_DIMENSION}x${MAX_DIMENSION} pixels or less`;
                reject(result);
                return;
            }
            result.dimensions = {
                width: img.width,
                height: img.height
            };
            resolve(result);
        };
        img.onerror = () => {
            URL.revokeObjectURL(img.src);
            result.isValid = false;
            result.error = 'Invalid image file';
            reject(result);
        };
        img.src = URL.createObjectURL(file);
    });
};

export const upscaleImage = async (file, token, options = {}) => {
    const {
        outputFormat = DEFAULT_OUTPUT_FORMAT,
        creativity = 0.35,
        seed = Math.floor(Math.random() * 100000)
    } = options;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('output_format', outputFormat);
    formData.append('creativity', creativity);
    formData.append('seed', seed);

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upscale`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || errorData.error || 'Upscaling failed');
        }

        return response;
    } catch (error) {
        console.error('Upscale request failed:', error);
        throw error;
    }
};

// New utility functions
export const getUpscaledDimensions = (width, height) => {
    return {
        width: Math.min(width * 4, MAX_DIMENSION * 4),
        height: Math.min(height * 4, MAX_DIMENSION * 4)
    };
};

export const getEstimatedFileSize = (originalSize) => {
    // Rough estimate: 4x dimensions = ~16x size, but with compression
    const estimatedSize = originalSize * 8; // Conservative estimate
    return formatFileSize(estimatedSize);
};