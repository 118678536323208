import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Star, ChevronLeft, ChevronRight } from 'lucide-react';

const RemoveTextReviews = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  
  const reviews = [
    {
      name: "Sarah K.",
      role: "Photographer",
      rating: 5,
      text: "Incredible tool! Removed watermarks perfectly from my old portfolio images.",
      avatar: "/avatars/sarah.jpeg"
    },
    {
      name: "Michael R.",
      role: "Digital Artist",
      rating: 5,
      text: "Best text removal tool I've used. The AI reconstruction is amazing.",
      avatar: "/avatars/michael.jpg"
    },
    {
      name: "Emma T.",
      role: "Marketing Manager",
      rating: 4,
      text: "Fast and efficient. Saved hours of manual editing work.",
      avatar: "/avatars/emma.jpg"
    }
  ];

  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        setActiveIndex((current) => (current + 1) % reviews.length);
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isPaused, reviews.length]);

  const next = () => {
    setActiveIndex((current) => (current + 1) % reviews.length);
  };

  const prev = () => {
    setActiveIndex((current) => (current - 1 + reviews.length) % reviews.length);
  };

  return (
    <div className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">
          What Our Users Say
        </h2>
        
        <div 
          className="relative max-w-3xl mx-auto"
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <div className="overflow-hidden">
            <motion.div
              animate={{ x: `-${activeIndex * 100}%` }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
              className="flex"
            >
              {reviews.map((review, index) => (
                <div
                  key={index}
                  className="w-full flex-shrink-0 px-4"
                >
                  <div className="bg-white rounded-xl p-8 text-center">
                    <img
                      src={review.avatar}
                      alt={review.name}
                      className="w-20 h-20 rounded-full mx-auto mb-4"
                    />
                    <div className="flex justify-center mb-4">
                      {[...Array(5)].map((_, i) => (
                        <Star
                          key={i}
                          className={`w-5 h-5 ${
                            i < review.rating ? 'text-yellow-400 fill-current' : 'text-gray-300'
                          }`}
                        />
                      ))}
                    </div>
                    <p className="text-gray-600 mb-4">{review.text}</p>
                    <p className="font-medium text-gray-900">{review.name}</p>
                    <p className="text-sm text-gray-500">{review.role}</p>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
          
          <button
            onClick={() => { prev(); setIsPaused(true); }}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-12 bg-white rounded-full p-2 shadow-lg hover:bg-gray-50"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          
          <button
            onClick={() => { next(); setIsPaused(true); }}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-12 bg-white rounded-full p-2 shadow-lg hover:bg-gray-50"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveTextReviews;